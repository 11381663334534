import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalContext, IGlobalContext } from "../context/global.context";
import {
  Button,
  Divider,
  Layout,
  notification,
  Pagination,
  Radio,
  Row,
  Spin,
  Table,
  Tag,
} from "antd";

import {
  getArticleCsv,
  listArticles,
  searchArticlesV2,
  translateArticles,
} from "../services";
import {
  IArticle,
  IJournal,
  IOrganisation,
  ISorter,
  ITag,
  QueryContentParamsModel,
  SupportedLanguage,
} from "../model";
import { BarChartOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import axios from "axios";
import FiltersDrawer, {
  FiltersDrawerValuesModel,
} from "./shared/FiltersDrawer";
import { Flex } from "./shared/global";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { useCheckFiltersSaved } from "~/utils/hooks";

const DEFAULT_PAGE_SIZE = 30;

export const ArticleList: React.FC = () => {
  const { tagList, organisationList } = useContext(
    GlobalContext
  ) as IGlobalContext;

  const [innerArticleList, setInnerArticleList] = useState<IArticle[]>([]);
  const [metricsMode, setMetricsMode] = useState<boolean>(false);

  const [filters, setFilters] = useState<FiltersDrawerValuesModel>();
  const [sorter, setSorter] = useState<ISorter>();

  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedArticles, setSelectedArticles] = useState<string[]>([]);
  const hasSavedFilters = useCheckFiltersSaved();

  const fetchArticles = async (limit?: number, offset?: number) => {
    const search = new URLSearchParams(location.search);

    if (!filters && !!search.get("language")) return;

    // To prevent fetching articles when the page is fetching with saved filters
    if (!filters && hasSavedFilters) return;

    try {
      const query: QueryContentParamsModel = {
        ...filters,
        limit: limit || DEFAULT_PAGE_SIZE,
        offset: offset || 0,
        ...sorter,
      };

      // TODO: get saved filters from localStorage

      let articleList;
      setIsLoading(true);

      if (filters?.search) {
        // Search API expects uid, not _id
        const medical_specialties = filters.medicalSpecialties
          ? tagList
              .filter((tag) => filters.medicalSpecialties?.includes(tag._id))
              .map((tag) => tag.uid)
          : undefined;

        articleList = await searchArticlesV2({
          ...query,
          medical_specialties,
        });

        if (articleList.hits.hits) {
          const resArticles = articleList.hits.hits.map(
            (article: { _source: { core: IArticle; filters: IArticle } }) => ({
              ...article._source.core,
              ...article._source.filters,
              owner: organisationList.find(
                (o) =>
                  o._id === (article._source.filters.owner as unknown as string)
              ),
            })
          );

          setInnerArticleList(resArticles);
          handlePaginationChange(1);
        }
      } else {
        if (query.status === "all") {
          delete query["status"];
        }
        articleList = await listArticles(query);

        if (articleList) {
          setInnerArticleList(articleList.docs);
          setCount(articleList.meta.total);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Couldn't search articles.", error);
      if (axios.isAxiosError(error))
        notification.error({
          message: "An error occurred",
          description: error.response?.data?.message,
        });
      setIsLoading(false);
    }
  };

  const handleDownloadCsv = async () => {
    const articleCsv = await getArticleCsv(selectedArticles);
    const blobConfig = new Blob([articleCsv], {
      type: "text/csv;charset=utf-8",
    });
    const blobUrl = URL.createObjectURL(blobConfig);

    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = "articles.csv";

    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(blobUrl);
    document.body.removeChild(link);
  };

  const handleSelectArticles = (selectedRowKeys: React.Key[]) => {
    const keys = selectedRowKeys as string[];

    if (selectedArticles.length && keys.length === 0) setSelectedArticles([]);
    else if (selectedArticles.length)
      setSelectedArticles([...selectedArticles, ...keys]);
    else setSelectedArticles(keys);
  };

  const handleFiltersChange = (values: FiltersDrawerValuesModel) => {
    setFilters(values);

    handlePaginationChange(1);
  };

  const handlePaginationChange = async (page: number, pageSize?: number) => {
    if (pageSize) {
      await fetchArticles(
        pageSize,
        (page - 1) * pageSize < 0 ? 0 : (page - 1) * pageSize
      );
    }
    setPage(page);
  };

  const openNotificationSuccess = (language: SupportedLanguage) => {
    notification["success"]({
      message: "Translation task sent for " + language.toLocaleUpperCase(),
      description: "The translation task was succesfully sent to the server.",
    });
  };

  const handleTranslation = async () => {
    const translationTask = {
      FR: await translateArticles(SupportedLanguage.FR),
    };

    if (translationTask.FR) openNotificationSuccess(SupportedLanguage.FR);
  };

  const saveFiltersLocaleStorage = () => {
    localStorage.setItem("articleListFilters", JSON.stringify(filters));
  };

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<IArticle> | SorterResult<IArticle>[]
  ) => {
    const _sorter = sorter as SorterResult<IArticle>;
    const sortOrder = _sorter.order === "ascend" ? "asc" : "desc";

    if (_sorter.column?.title === "Creation date")
      setSorter({ sortBy: "creationDate", sortOrder });

    if (_sorter.column?.title === "Publication date")
      setSorter({ sortBy: "publicationDate", sortOrder });
  };

  useEffect(() => {
    fetchArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sorter]);

  const columns: ColumnsType<IArticle> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 480,
      render: (_text: string, record: IArticle) => (
        <Link
          onClick={saveFiltersLocaleStorage}
          to={`/content-management/article/${record.slug}`}
        >
          {record.title}
        </Link>
      ),
    },
    {
      title: "Status",
      dataIndex: "title",
      key: "title",
      render: (_text: string, record: IArticle) => (
        <Tag
          color={
            record?.meta
              ? record?.meta?.status === "draft"
                ? "warning"
                : "success"
              : (record as IArticle & { status: string })?.status === "draft"
              ? "warning"
              : "success"
          }
        >
          {record?.meta
            ? record?.meta?.status?.toLocaleUpperCase()
            : (
                record as IArticle & { status: string }
              )?.status?.toLocaleUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Access",
      dataIndex: "isPublic",
      key: "isPublic",
      width: 80,
      render: (isPublic: boolean) =>
        isPublic ? (
          <Tag color='green'>{"✅ Public"}</Tag>
        ) : (
          <Tag color='purple'>{"🔐 Private"}</Tag>
        ),
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      width: 150,
      render: (owner: IOrganisation) => {
        if (!owner) return <span>{"N/A"}</span>;

        return (
          <Link to={`/sensitive-data/organisations/${owner?.uid}`}>
            {owner.name}
          </Link>
        );
      },
    },
    {
      title: "Creation date",
      dataIndex: "title",
      key: "title",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      render: (_text: string, record: IArticle) => {
        return new Date(record?.meta?.creationDate)?.toLocaleDateString();
      },
    },
    {
      title: "Publication date",
      dataIndex: "publication_date",
      key: "publication_date",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      render: (date: string) => {
        return new Date(date).toLocaleDateString();
      },
    },
    {
      title: "Medical Specialties",
      dataIndex: "medical_specialties",
      key: "medical_specialties",
      width: 480,
      render: (medical_specialties: Array<ITag & { en: string }>) =>
        medical_specialties
          ?.map((medicalSpecialty) => medicalSpecialty.translations.en)
          .join(", "),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: 480,
      render: (tags: Array<ITag>) =>
        tags?.map((tag) => tag.translations.en).join(", "),
    },
    {
      title: "Journal",
      dataIndex: "journal",
      key: "journal",
      width: 240,
      render: (journal: IJournal | string) => {
        return typeof journal === "string" ? journal : journal?.name;
      },
    },
    {
      title: "Primary author",
      dataIndex: "primary_author",
      key: "primary_author",
      width: 240,
    },
  ];

  const columnsMetrics: ColumnsType<IArticle> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 540,
      render: (_text: string, record: IArticle) => (
        <Link to={`/content-management/article/${record.slug}`}>
          {record.title}
        </Link>
      ),
    },
    {
      title: "Creation date",
      dataIndex: "title",
      key: "title",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      render: (_text: string, record: IArticle) => {
        return new Date(record?.meta?.creationDate)?.toLocaleDateString();
      },
    },
    {
      title: "Publication date",
      dataIndex: "publication_date",
      key: "publication_date",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      render: (date: string) => {
        return new Date(date).toLocaleDateString();
      },
    },
    {
      title: "Total Views",
      dataIndex: "metrics",
      key: "metrics",
      width: 100,
      render: (metrics: Record<string, number>) => metrics?.views,
    },
    {
      title: "Source Views",
      dataIndex: "metrics",
      key: "metrics",
      width: 120,
      render: (metrics: Record<string, number>) => metrics?.sourceViews || 0,
    },
    {
      title: "Saves",
      dataIndex: "metrics",
      key: "metrics",
      width: 100,
      render: (metrics: Record<string, number>) => metrics?.saveds,
    },
    {
      title: "Likes",
      dataIndex: "metrics",
      key: "metrics",
      width: 100,
      render: (metrics: Record<string, number>) => metrics?.likes,
    },
    {
      title: "Shares",
      dataIndex: "metrics",
      key: "metrics",
      width: 80,
      render: (metrics: Record<string, number>) => metrics?.shares,
    },
  ];

  return (
    <Layout
      style={{
        overflow: "hidden",
        boxSizing: "border-box",
        height: "100vh",
      }}
    >
      <Layout.Content
        style={{
          padding: "20px 50px 50px",
          boxSizing: "border-box",
        }}
      >
        <Row
          justify='space-between'
          align='middle'
          style={{ margin: "20px 0" }}
        >
          <h1 style={{ fontWeight: 800, fontSize: "30px", margin: 0 }}>
            Articles
          </h1>
          <div>
            <Radio.Group
              onChange={() => setMetricsMode(!metricsMode)}
              value={metricsMode}
              style={{ marginBottom: 8 }}
            >
              <Radio.Button value={true}>
                <Flex align='center' gap={8}>
                  <BarChartOutlined />
                  {"Metrics"}
                </Flex>
              </Radio.Button>
              <Radio.Button value={false}>
                <Flex align='center' gap={8}>
                  <UnorderedListOutlined />
                  {"Management"}
                </Flex>
              </Radio.Button>
            </Radio.Group>

            <Divider type='vertical' />
            <Button onClick={handleTranslation} style={{ marginRight: 10 }}>
              Translate articles
            </Button>
            <Button onClick={handleDownloadCsv}>Download as CSV</Button>
            <Link to={"/content-management/article/create/"}>
              <Button type='primary' style={{ marginLeft: 10 }}>
                New article
              </Button>
            </Link>
          </div>
        </Row>
        <FiltersDrawer onChange={handleFiltersChange} />

        <div style={{ height: 16 }} />
        <Spin spinning={isLoading} tip='Loading...'>
          <Table
            sticky
            size='small'
            columns={metricsMode ? columnsMetrics : columns}
            pagination={false}
            rowSelection={{
              onChange: handleSelectArticles,
              selectedRowKeys: selectedArticles,
            }}
            dataSource={innerArticleList.map((article) => ({
              ...article,
              key: article._id,
            }))}
            scroll={{
              x: "max-content",
              y: "calc(100vh - 300px)",
            }}
            onChange={handleTableChange}
          />
        </Spin>
        <Pagination
          style={{ marginTop: "10px" }}
          onChange={handlePaginationChange}
          total={count}
          current={page}
          defaultCurrent={1}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          pageSizeOptions={["10", "20", "50", "100"]}
        />
      </Layout.Content>
    </Layout>
  );
};

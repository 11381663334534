import { FiltersDrawerValuesModel } from "~/components/shared/FiltersDrawer";
import { CrawlingSourceEnum } from "./dto/crawling-dto";

export interface ISorter {
  sortBy?: "creationDate" | "publicationDate" | "status" | "name";
  sortOrder?: "asc" | "desc";
}

export interface QueryContentParamsModel
  extends FiltersDrawerValuesModel,
    ISorter {}

export interface IQuery extends ISorter {
  limit?: number;
  offset?: number;
  content_format?:
    | "Article"
    | "Video"
    | "Infographic"
    | "Podcast"
    | "Guideline";
  company?: string | null;
  country?: string;
  device?: string;
  journal?: string | null;
  language?: SupportedLanguage;
  main_specialty?: string;
  mainSpecialty?: string;
  medical_specialties?: string | null;
  medicalSpecialty?: string | null;
  organisation?: string | null;
  owner?: string | null;
  origin?: string;
  profession?: string;
  tag?: string | null;
  sort?: "meta.creationDate" | "publication_date";
  videoFormat?: "article" | "story";
  room?: string;
  search?: string;
}

export interface IQueryParsing {
  limit: number;
  offset: number;
  sortBy?: "creationDate" | "status" | "name";
  sortOrder?: "asc" | "desc";
  status?: IParsing["meta"]["status"] | undefined;
  taskId?: string;
  batchName?: string;
}

export interface IImage {
  _id: string;
  path: string;
  url: string;
  legend?: string;
  order: number;
}

export interface IArticle {
  _id: string;
  id: string;
  abstract_provided: boolean;
  associatedContent: IContent[];
  article_type: string;
  authors: string[];
  background: string;
  citation_count: number;
  comments: string[];
  conclusion: string[];
  disclosure: string;
  doi: string;
  edito: string;
  formatting_level: "high" | "med" | "low";
  images: IImage[];
  impact_factor: number;
  journal: IJournal;
  keywords: string[];
  legend: string;
  legends: string[];
  language: SupportedLanguage;
  limitations: string;
  meta: {
    creationDate: Date;
    lastModified: Date;
    status: "draft" | "published";
  };
  medical_specialties: string[] | ITag[];
  methodology: string;
  metrics: {
    saveds: number;
    favorites: number;
    likes: number;
    shares: number;
    views: number;
    sourceViews: number;
  };
  title: string;
  objectives: string[];
  owner?: IOrganisation;
  primary_author: string;
  publication_date: Date;
  results: string[];
  slug: string;
  tags: string[] | ITag[];
  speech?: {
    path: string;
    url: string;
  };
  video?: IVideo;
  isPublic: boolean;
}

export enum VideoFormatEnum {
  ARTICLE = "article",
  STORY = "story",
}

export interface IInfographic extends IContent {
  image: IImage;
  thumbnail: IImage;
}

export interface IApiVideo {
  title?: string;
  language?: SupportedLanguage;
  videoId?: string;
  thumbnail?: string;
  player?: string;
}

export interface IVideo extends IContent {
  sourceURL: string;
  embedURL: string;
  videoFormat: VideoFormatEnum;
  apiVideo?: IApiVideo;
  thumbnail: IImage;
}

export enum VideoRequestStatus {
  WAITING = "Waiting",
  REJECTED = "Rejected",
  DONE = "Done",
  CANCELED = "Canceled",
  WAITING_VALIDATION = "WaitingValidation",
}

export interface IVideoRequestFeedback {
  createdAt: Date;
  message: string;
  _id: string;
  timer: string;
  status: "Done" | "New";
}

export interface IVideoRequest {
  _id: string;
  id: string;
  name: string;
  createdAt: string;
  organisation: IOrganisation;
  pdf: {
    path: string;
    url: string;
  };
  status: VideoRequestStatus;
  videos: Array<
    IApiVideo & {
      videoContent: IVideo;
      feedbacks: Array<IVideoRequestFeedback>;
    }
  >;
}

export enum ContentFormatsEnum {
  ARTICLE = "Article",
  VIDEO = "Video",
  INFOGRAPHIC = "Infographic",
  PODCAST = "Podcast",
  GUIDELINE = "Guideline",
  NECTAR = "Nectar",
}

export interface IComment extends Document {
  article: IArticle;
  message: string;
  user: IUser;
  meta: {
    creationDate: Date;
    lastModified: Date;
  };
}

export interface IContent {
  readonly content_format: ContentFormatsEnum;
  _id: string;
  id: string;
  associatedArticles: IArticle[];
  associatedContent: IContent[];
  congress: ICongress;
  company: ICompany;
  journal: IJournal;
  keywords: string[];
  language: SupportedLanguage;
  medical_specialties: string[] | ITag[];
  meta: {
    creationDate: Date;
    lastModified: Date;
    status: "draft" | "published";
  };
  metrics?: {
    saveds: number;
    favorites: number;
    shares: number;
    views: number;
  };
  // organisations: string[] | IOrganisation[];
  owner?: IOrganisation;
  isPublic: boolean;
  publication_date: Date;
  publisher: SupportedPublisher;
  sites: string[] | ISite[];
  slug: string;
  tags: string[] | ITag[];
  title: string;
  user: IUser;
  versions: {
    [key: string]: unknown;
  };
}

export interface IJournal {
  _id: string;
  description: string;
  image?: string | IImage;
  impact_factor: string;
  h_index?: string;
  meta: {
    creationDate: Date;
    lastModified: Date;
  };
  name: string;
  sjr: string;
  uid: string;
}

export interface IHistoryItem {
  title: string;
  slug: string;
}

export interface IHistory {
  history: IHistoryItem[];
}

export interface IHistoryCounter {
  [key: string]: {
    title: string;
    readingCount: number;
  };
}

export interface IUserAward {
  label: string;
  content: string;
}

export interface IProfession {
  _id: string;
  uid: string;
  translations: TranslationsType;
  medical_specialties: ITag[];
  tags: ITag[];
}

export interface IUser {
  _id: string;
  uid: string;
  email: string;
  city: string;
  country: string;
  employer: string;
  firstname: string;
  lastname: string;
  fullname: string;
  image: IImage;
  lemonCertified: boolean;
  medical_specialty: string;
  medical_subspecialty: string;
  profession: string;
  username: string;
  roles: IUserRole[];
  language: SupportedLanguage;
  profilePicture: {
    name: string;
    url: string;
    path: string;
  };
  meta: {
    creationDate: Date;
    lastModified: Date;
    lastLogin: Date;
    firstLogin: boolean;
  };
  sites: ISite[];
  organisations: IOrganisation[];
  device?: string;
  savedContent: IContent[];
  savedVideos: IVideo[] | boolean;
  likes: IArticle[];
  dailyGptCreditUsed: number;
  enabled: boolean;
}

export interface IUserAdmin extends IUser {
  adminPermissions: {
    label: PermissionLabel;
    permissions: PermissionEnum[];
  }[];
}

export enum IUserRole {
  ADMIN = "admin",
  USER = "user",
  ORGANISATION = "organisation",
  SUPER_ADMIN = "super_admin",
}

export interface IMetric {
  favorites: number;
  shares: number;
}

export interface ILoginResponse {
  acessToken: string;
  accessTokenExpiration: number;
  user: IUser;
}

export interface IInvite {
  _id: string;
  code: string;
  organisation: IOrganisation;
  organisationUid: string;
  maxUses: number | null;
  uses: number;
  createdAt: Date;
  expiresAt: Date | null;
}

export interface IOrganisation {
  _id: string;
  uid: string;
  name: string;
  description: string;
  logo: IImage;
  rank: number;
  organisationSettings: {
    allowedLanguages: SupportedLanguage[];
    mainSpecialty: ITag;
    medicalSpecialties: ITag[];
    tags: ITag[];
    professions: IProfession[];
    backgroundColor?: string;
    qrfyFolder: number;
    subtitle: string;
    discoveryFilter: boolean;
    features: Array<{
      name: FeatureEnum;
      enable: boolean;
      limit?: number;
      currentUsage?: number;
    }>;
  };
}

export interface ICongress {
  _id: string;
  language: SupportedLanguage;
  name: string;
  description: string;
  website: string;
  image: IImage;
  organisations: IOrganisation[];
}

export interface IConversation {
  _id: string;
  users: IUser[];
  messages: IMessage[];
  meta: {
    creationDate: Date;
    lastModified: Date;
  };
}

export interface IMessage {
  message: string;
  sender: IUser;
  meta: {
    creationDate: Date;
  };
}

export interface ITag {
  _id: string;
  uid: string;
  translations: TranslationsType;
  parent?: ITag;
}

export interface ISite {
  name: string;
  description: string;
  siteType: "clinic" | "hospital";
  address: string;
  image: string;
  organisation: IOrganisation;
  uid: string;
}

export interface ICrawlingItem {
  _id: string;
  title: string;
  journal: string;
  amount_citations: number;
  year_publication: number;
  other_content: {
    figures: [
      [
        {
          url: string;
          legend: string;
        }
      ]
    ];
  };
  text_content: {
    raw_text: string;
    abstract: {
      background: string;
      methodology: string;
      results: string;
      conclusions: string;
      content: string;
    };
    introduction: string;
    force_introduction: string;
    conclusion: string;
    methodology: string;
    results: string;
    content: {
      limitation: string;
      discussion: string;
      other_sections: string[];
    };
  };
  disclosure: string;
  metadata: {
    authors: [string];
    keywords: [string];
  };
  doi: string;
  link_pdf: string;
  id_link: string;
  name_pdf: string;
  gcs_pdf_path: string;
}

export interface IPaginedResult<T> {
  docs: T[];
  meta: {
    total: number;
    limit: number;
    offset: number;
  };
}

export interface ICrawling {
  _id: string;
  id: string;
  title: string;
  results: ICrawlingItem[];
  meta: {
    creationDate: Date;
    lastModified: Date;
    status: "pending" | "progress" | "success" | "failure";
  };
  request: {
    dates: {
      from: Date;
      to: Date;
    };
    custom_query: string;
    keywords: string[];
    journals: string[];
    authors: string[];
    titles: string[];
    publicationTypes: string[];
    languages: SupportedLanguage[];
    nb_articles?: number;
  };
  taskId: string;
  medical_specialties: string[];
  organisations: IOrganisation[];
  sites: ISite[];
  sources: CrawlingSourceEnum[];
}

export interface IParsing {
  article: IArticle;
  doi: string;
  taskId: string;
  meta: {
    creationDate: Date;
    lastModified: Date;
    status: "pending" | "progress" | "success" | "failure" | "duplicate";
  };
  organisations: IOrganisation[];
  pdfName: string;
  sites: ISite[];
  result: {
    summarized: {
      title: string;
      objectives: string[];
      background: string;
      methodology: string;
      limitations: string;
      results: string[];
      conclusion: string[];
      doi: string;
      journal: string;
      publication_date: Date;
      primary_author: string;
      authors: string[];
      medical_specialties: string[];
    };
  };
  crawling: ICrawling;
  batchName?: string;
  id: string;
  _id: string;
}

export interface ICompanyInfo {
  _id: string;
  content: string;
  creation_date: Date;
  cta: string;
  event_date: Date;
  link: string;
  type: string;
}

export interface ICompany {
  _id: string;
  description: string;
  images: IImage[];
  infos: ICompanyInfo[];
  meta: {
    creationDate: Date;
    lastModified: Date;
    status?: "draft" | "published";
  };
  metrics: {
    views: number;
    shares: number;
  };
  language: SupportedLanguage;
  name: string;
  type: string;
  video_link: string;
  website: string;
}

export interface IPlaylist {
  _id: string;
  id: string;
  company: ICompany;
  description: string;
  images: IImage[];
  medical_specialties: string[] | ITag[];
  publicationDate: Date;
  meta: {
    creationDate: Date;
    lastModified: Date;
    status?: "draft" | "published";
  };
  metrics: {
    articles: number;
    journals: number;
    saveds: number;
    shares: number;
    views: number;
  };
  language: SupportedLanguage;
  // organisations: IOrganisation[];
  owner?: IOrganisation;
  playlist: IArticle[];
  rank: number;
  sponsored: boolean;
  tags: string[] | ITag[];
  title: string;
  user: string;
  isPublic: boolean;
  publisher: SupportedPublisher;
  playlistContent: IContent[];
}

export interface ILemonRequest {
  id: string;
  status: "Approved" | "Waiting" | "Rejected";
  user: IUser;
  userId: string;
}

export interface INectar extends IContent {
  _id: string;
  answer: string;
  highlight: boolean;
  associatedArticles: IArticle[];
  metrics: {
    saveds: number;
    favorites: number;
    likes: number;
    shares: number;
    views: number;
  };
  question: string;
  slug: string;
  title: string;
}

export interface INectarHighlight {
  language: SupportedLanguage;
  organisation: IOrganisation;
  organisationId: string;
  highlight: Array<{
    position: number;
    nectar: INectar;
    nectarId: string;
  }>;
  id: string;
}

export interface INectarQuestion {
  answer: string;
  id: string;
  _id: string;
  createdAt: Date;
  language: SupportedLanguage;
  sourcesId: string[];
  taskId: string;
  question: string;
  sources: IArticle[];
  organisation: IOrganisation;
  organisationId: string;
  errorMessage?: string;
}

export interface IRoom {
  id: string;
  allowedLanguages: SupportedLanguage[];
  primaryColor: string;
  secondaryColor: string;
  contents: IContent[];
  discoveryFilter: boolean;
  articles: IArticle[];
  isPublic: boolean;
  mainSpecialty: ITag;
  medicalSpecialties: ITag[];
  tags: ITag[];
  name: string;
  organisation: IOrganisation;
  playlists: IPlaylist[];
  professions: IProfession[];
  type: RoomType;
  logo: IImage;
  pinnedContents: Array<{
    content: string;
    language: SupportedLanguage;
  }>;
}

// Enums

export enum SupportedLanguage {
  EN = "en",
  FR = "fr",
  IT = "it",
  ES = "es",
  DE = "de",
  PT = "pt",
  AR = "ar",
}

export enum SupportedPublisher {
  JOURNAL = "journal",
  COMPANY = "company",
  CONGRESS = "congress",
}

export type TranslationsType = {
  [key in SupportedLanguage]: string;
};

export enum PermissionLabel {
  Sources = "sources",
  Content = "content",
  Referential = "referential",
  SensitiveData = "sensitive data",
}

export enum PermissionEnum {
  Read = "read",
  Write = "write",
  Publish = "publish",
  Delete = "delete",
}

export enum OrganisationUid {
  JUISCI = "juisci",
  BIOCODEX = "biocodex-rd",
  SFRO = "sfro",
  SFR = "sfr",
  ROYAL_CANIN = "royal-canin",
  SPILF = "spilf",
  MGEN = "mgen",
  SANOFI = "sanofi-bodypain-app-staging",
  ESR = "esr",
  DEMO = "dashboard-demo",
  OREAL = "loreal-dermato-portal-staging",
  TAKEDA = "takeda-lymphoma-app",
  UCB = "ucb-rare-diseases",
}

export enum FeatureEnum {
  JuisciGPT = "JuisciGPT",
  Videos = "Videos",
  Infographics = "Infographics",
  Users = "Users",
  Analytics = "Analytics",
  QrCodes = "QrCodes",
  Playlists = "Playlists",
  Nectars = "Nectars",
}

export enum RoomType {
  Default = "Default",
  ExternalApi = "ExternalApi",
  Audio = "Audio",
}

export type TranslateArticleErrorType = {
  status: string;
  message: string;
};

import React, { useEffect, useState } from "react";
import { Button, Input, Layout, Pagination, Row, Select, Table } from "antd";

import {
  getAnalyticsCsv,
  listAnalytics,
  listArticles,
  searchArticles,
} from "../services";
import { IArticle, SupportedLanguage } from "../model";
import { languageOptions } from "../utils/helpers";

export const AnalyticsList: React.FC = () => {
  const [innerArticleList, setInnerArticleList] = useState<IArticle[]>([]);
  const [language, setLanguage] = useState<SupportedLanguage>(
    SupportedLanguage.EN
  );
  const [count, setCount] = useState<number>(0);
  const [blobUrl, setBlobUrl] = useState<string>();
  const [searchValue, setSearchValue] = useState<string>();

  const fetchAnalytics = async (limit?: number, offset?: number) => {
    const query = {
      limit: limit || 100,
      offset: offset || 0,
      language,
    };

    if (searchValue) {
      const articleList = await searchArticles(searchValue, query);

      if (articleList.docs) {
        setInnerArticleList(articleList.docs);
        handlePaginationChange(1);
      }
    } else {
      const articleList = await listAnalytics(query);

      if (articleList) {
        setInnerArticleList(articleList);
      }

      const articleCount = await listArticles(query);
      if (articleCount) {
        setCount(articleCount.meta.total);
      }

      console.log(innerArticleList);
    }
  };

  const fetch = async () => {
    if (!blobUrl) {
      const analyticsCsv = await getAnalyticsCsv();
      const blobConfig = new Blob([analyticsCsv], {
        type: "text/csv;charset=utf-8",
      });
      setBlobUrl(URL.createObjectURL(blobConfig));
    }
  };

  const handleLanguageChange = (language: SupportedLanguage) => {
    setLanguage(language);
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    handlePaginationChange(1);
  };

  const handlePaginationChange = async (page: number, pageSize?: number) => {
    if (pageSize) {
      await fetchAnalytics(
        pageSize,
        (page - 1) * pageSize < 0 ? 0 : (page - 1) * pageSize
      );
    }
  };

  useEffect(() => {
    fetchAnalytics();
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, language]);

  return (
    <Layout
      style={{
        overflow: "hidden",
        boxSizing: "border-box",
        height: "100vh",
      }}
    >
      <Layout.Content
        style={{
          padding: "20px 50px 50px",
          boxSizing: "border-box",
        }}
      >
        <Row
          justify='space-between'
          align='middle'
          style={{ margin: "20px 0" }}
        >
          <h1 style={{ fontWeight: 800, fontSize: "30px" }}>Analytics</h1>
          <div>
            <Select
              defaultValue={language}
              style={{ marginRight: 10 }}
              onSelect={handleLanguageChange}
              options={languageOptions}
            />
            <Button href={blobUrl} download='analytics.csv'>
              Download as CSV
            </Button>
          </div>
        </Row>
        <div className='site-layout-content'>
          <Input.Search
            style={{
              marginBottom: "20px",
            }}
            placeholder='Search article'
            allowClear
            onSearch={handleSearchChange}
          />
          <Table
            columns={[
              {
                title: "Article",
                dataIndex: "title",
                key: "title",
              },
              {
                title: "Clicks",
                dataIndex: "metrics",
                key: "metrics",
                width: 100,
                render: (metrics: IArticle["metrics"]) => {
                  return metrics.views;
                },
              },
              {
                title: "Likes",
                dataIndex: "metrics",
                key: "metrics",
                width: 100,
                render: (metrics: IArticle["metrics"]) => {
                  return metrics.likes;
                },
              },
              {
                title: "Saves",
                dataIndex: "metrics",
                key: "metrics",
                width: 100,
                render: (metrics: IArticle["metrics"]) => {
                  return metrics.saveds;
                },
              },
              {
                title: "Shares",
                dataIndex: "metrics",
                key: "metrics",
                width: 100,
                render: (metrics: IArticle["metrics"]) => {
                  return metrics.shares;
                },
              },
            ]}
            pagination={false}
            scroll={{
              x: "max-infographic",
              y: "calc(100vh - 300px)",
            }}
            sticky
          />
          <Pagination
            style={{ marginTop: "10px" }}
            onChange={handlePaginationChange}
            total={count}
            defaultCurrent={1}
            defaultPageSize={50}
            pageSizeOptions={["10", "20", "50", "100"]}
          />
        </div>
      </Layout.Content>
    </Layout>
  );
};

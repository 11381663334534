import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Upload,
  message,
} from "antd";
import { IVideoRequest, SupportedLanguage, VideoRequestStatus } from "../model";
import {
  getVideoRequestList,
  postVideoRequest,
  rejectVideoRequest,
  validateVideoRequest,
} from "../services";
import moment from "moment";
import {
  ArrowLeftOutlined,
  BarChartOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  EditOutlined,
  SyncOutlined,
  UnorderedListOutlined,
  UploadOutlined,
  VideoCameraAddOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { languageOptionsOrganisation } from "~/utils/helpers";
import { ColumnsType } from "antd/lib/table";

const VideoRequestList = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [requestsList, setRequestsList] = useState<Array<IVideoRequest> | null>(
    null
  );
  const [selectedRequest, setSelectedRequest] = useState<string | null>(null);
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [rejectReason, setRejectReason] = useState<string>("");
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [fetchStatus, setFetchStatus] = useState<VideoRequestStatus[] | null>(
    null
  );

  const getRequestList = async () => {
    const { docs: requests } = await getVideoRequestList(
      !fetchStatus?.length || !fetchStatus ? undefined : fetchStatus
    );
    setRequestsList(requests);
  };

  const handleSubmit = async () => {
    if (!selectedRequest) return;
    setIsUploading(true);
    const videoFile = form.getFieldValue("video").file.originFileObj as File;
    const params = {
      title: form.getFieldValue("title"),
      language: form.getFieldValue("language"),
    };

    await postVideoRequest(selectedRequest, videoFile, params);
    await getRequestList();

    form.resetFields();
    setIsUploading(false);
    setSelectedRequest(null);
    setSelectedVideo(null);
    message.success("Video uploaded successfully");
  };

  const handleValidateRequest = async (
    status: VideoRequestStatus,
    requestId: string
  ) => {
    if (status !== VideoRequestStatus.WAITING) return;

    await validateVideoRequest(requestId);
    await getRequestList();
    message.success("Video request validated successfully!");
  };

  const handleRejectRequest = async () => {
    if (!selectedRequest) return;
    await rejectVideoRequest(selectedRequest, rejectReason);
    await getRequestList();

    setSelectedRequest(null);
    setShowRejectModal(false);
    setRejectReason("");
    message.success("Video request rejected successfully!");
  };

  useEffect(() => {
    getRequestList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStatus]);

  useEffect(() => {
    if (!selectedVideo) return;

    const video = requestsList
      ?.find((req) => req._id === selectedRequest)
      ?.videos.find((video) => video.videoId === selectedVideo);

    if (!video) return;

    form.setFieldsValue({
      title: video.title,
      language: video.language,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVideo]);

  const columns: ColumnsType<IVideoRequest> = [
    {
      title: "Request label",
      dataIndex: "name",
      key: "name",
      width: 400,
    },
    {
      title: "Organisation",
      dataIndex: ["organisation", "name"],
      key: "organisation",
      width: 200,
    },
    {
      title: "Creation Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (text) => moment(new Date(text)).format("MM/DD/YYYY"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (text) => (
        <Tag
          icon={
            text === VideoRequestStatus.DONE ? (
              <CheckOutlined />
            ) : text === VideoRequestStatus.WAITING ||
              text === VideoRequestStatus.WAITING_VALIDATION ? (
              <SyncOutlined spin />
            ) : (
              <CloseCircleOutlined />
            )
          }
          color={
            text === VideoRequestStatus.WAITING ||
            text === VideoRequestStatus.WAITING_VALIDATION
              ? "orange"
              : text === VideoRequestStatus.REJECTED
              ? "red"
              : text === VideoRequestStatus.CANCELED
              ? "purple"
              : "green"
          }
        >
          {text}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",

      render: (_, record) => (
        <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
          <a
            href={record.pdf.url}
            target='_blank'
            download={record._id + ".pdf"}
          >
            <Button type='link' htmlType='button'>
              Download PDF
            </Button>
          </a>
          <Divider type='vertical' />

          {record.status === VideoRequestStatus.WAITING_VALIDATION ? (
            <Select
              onChange={(language) =>
                navigate(
                  `/content-management/video/requests/review/${record._id}/${language}`
                )
              }
              placeholder='Review video'
              style={{ width: 200 }}
              options={
                record.videos.map((video) => ({
                  label: `Review Video ${video.language?.toUpperCase()}`,
                  value: video.language,
                  disabled: !video.player,
                })) || []
              }
            />
          ) : null}
          <Divider type='vertical' />

          {record.status === VideoRequestStatus.WAITING &&
          record.videos.length ? (
            <Select placeholder='Preview' value={null} style={{ width: 110 }}>
              {record.videos.map((video) => (
                <Select.Option key={video.videoId}>
                  <a href={video.player} target='_blank'>
                    {`Preview ${video.language?.toUpperCase()}`}
                  </a>
                </Select.Option>
              ))}
            </Select>
          ) : null}

          {record.status === VideoRequestStatus.WAITING &&
          record.videos.length ? (
            <Select
              placeholder='Edit'
              value={null}
              onChange={(videoId) => {
                if (record._id) {
                  setSelectedRequest(record._id);
                  setSelectedVideo(videoId);
                }
              }}
              style={{ width: 100 }}
            >
              {record.videos.map((video) => (
                <Select.Option key={video.videoId} value={video.videoId}>
                  {`Edit ${video.language?.toUpperCase()}`}
                </Select.Option>
              ))}
            </Select>
          ) : null}

          {record.status === VideoRequestStatus.DONE ? (
            record.videos.map((video) => (
              <Button
                key={video.videoId}
                style={{ width: 120 }}
                icon={<EditOutlined />}
                type={"default"}
                htmlType='button'
                disabled={video.videoContent === null}
                onClick={() => {
                  if (video.videoContent) {
                    navigate(
                      "/content-management/video/" + video.videoContent?.slug
                    );
                  }
                }}
              >
                {video.videoContent
                  ? `Edit ${video.language?.toUpperCase()}`
                  : `[Deleted] ${video.language?.toUpperCase()}`}
              </Button>
            ))
          ) : record.status == VideoRequestStatus.WAITING &&
            record.videos.length > 0 ? (
            <Button
              style={{
                width: 110,
                background: "#16a34a",
                borderColor: "#16a34a",
              }}
              icon={<CheckCircleOutlined />}
              type={"primary"}
              htmlType='button'
              onClick={() =>
                record._id &&
                handleValidateRequest(
                  record.status as VideoRequestStatus,
                  record._id
                )
              }
            >
              Validate
            </Button>
          ) : record.status === VideoRequestStatus.WAITING ? (
            <Button
              danger
              type='default'
              icon={<CloseCircleOutlined />}
              onClick={() => {
                setSelectedRequest(record._id);
                setShowRejectModal(true);
              }}
            >
              Reject
            </Button>
          ) : null}

          <Divider type='vertical' />
          {record.videos.length <
            languageOptionsOrganisation(record.organisation).length &&
          record.status === VideoRequestStatus.WAITING ? (
            <Button
              style={{ width: 120 }}
              type='primary'
              htmlType='button'
              onClick={() => record._id && setSelectedRequest(record._id)}
            >
              Upload
            </Button>
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <Layout
      style={{
        overflow: "hidden",
        boxSizing: "border-box",
        height: "100vh",
      }}
    >
      <Layout.Content
        style={{
          padding: "20px 50px 50px",
          boxSizing: "border-box",
        }}
      >
        <Row
          justify='space-between'
          align='middle'
          style={{ margin: "20px 0" }}
        >
          <Col>
            <h1 style={{ fontWeight: 800, fontSize: "30px", margin: 0 }}>
              Video Requests
            </h1>
          </Col>
          <Col>
            <Select
              placeholder='Filter by status'
              style={{ width: 300 }}
              mode='multiple'
              onChange={(status) => setFetchStatus(status)}
              options={Object.values(VideoRequestStatus).map((status) => ({
                label: status,
                value: status,
              }))}
            />
            <Divider type='vertical' />
            <Button
              icon={<VideoCameraAddOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => navigate("/content-management/video/requests")}
              type={"primary"}
            >
              Requests
            </Button>
            <Button
              icon={<UnorderedListOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => navigate("/content-management/video")}
              type={"default"}
            >
              Management
            </Button>
            <Button
              icon={<BarChartOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => navigate("/content-management/video?metrics=true")}
              type={"default"}
            >
              Metrics
            </Button>
          </Col>
        </Row>
        <Modal
          okType='danger'
          title='Are you sure you want to reject this request?'
          open={showRejectModal && !!selectedRequest}
          centered
          onOk={() => handleRejectRequest()}
          onCancel={() => {
            setShowRejectModal(false);
            setSelectedRequest(null);
          }}
          okText='Reject'
        >
          <Input.TextArea
            value={rejectReason}
            placeholder='Reason of rejection:'
            onChange={(e) => setRejectReason(e.target.value)}
          />
        </Modal>

        <Modal
          open={!!selectedRequest && !showRejectModal}
          onCancel={() => setSelectedRequest(null)}
          centered
          title='Upload video for a request'
          onOk={() => form.submit()}
          confirmLoading={isUploading}
        >
          <Form form={form} onFinish={() => handleSubmit()}>
            <Form.Item name='video' label='Video File (.mp4 only)'>
              <Upload
                maxCount={1}
                accept='.mp4'
                customRequest={(payload) => {
                  if (payload.onSuccess) payload.onSuccess("ok");
                }}
                onChange={({ file }) => {
                  if (file.status === "done") {
                    message.success(`${file.name} file uploaded successfully`);
                  } else if (file.status === "error") {
                    message.error(`${file.name} file upload failed.`);
                  }
                }}
              >
                <Button htmlType='button' icon={<UploadOutlined />}>
                  Upload Video
                </Button>
              </Upload>
            </Form.Item>

            <Form.Item name='title' label='Title'>
              <Input placeholder='Title' type='text' />
            </Form.Item>

            {!selectedVideo && (
              <Form.Item name='language' label='Language'>
                <Select
                  placeholder='Video language'
                  options={languageOptionsOrganisation(
                    requestsList?.find((req) => req._id === selectedRequest)
                      ?.organisation
                  ).map((lang) => ({
                    ...lang,
                    disabled: requestsList
                      ?.find((req) => req._id === selectedRequest)
                      ?.videos.map((video) => video.language)
                      .includes(lang.value as SupportedLanguage),
                  }))}
                />
              </Form.Item>
            )}
          </Form>
        </Modal>

        <Table
          columns={columns}
          dataSource={requestsList?.map((request) => ({
            ...request,
            key: request._id,
          }))}
          scroll={{ x: "max-content", y: "calc(100vh - 300px)" }}
          footer={() => (
            <Button
              icon={<ArrowLeftOutlined />}
              type='link'
              onClick={() => navigate(-1)}
            >
              Back to videos
            </Button>
          )}
        />
      </Layout.Content>
    </Layout>
  );
};

export default VideoRequestList;

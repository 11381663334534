import { IMapping } from ".";
import { IJournal, ITag, SupportedLanguage } from "../model";

export const createArticleMapping = (
  journalList?: IJournal[],
  tagList?: ITag[],
  parentList?: string[]
): { [key: string]: IMapping } => ({
  language: {
    label: "Language",
    type: "select",
    enum: Object.values(SupportedLanguage),
    location: "card",
    required: true,
  },
  publication_date: {
    label: "Publication date",
    type: "date",
    location: "card",
    required: true,
  },
  medical_specialties: {
    label: "Medical specialties",
    type: "multipleTag",
    enum: tagList?.filter(
      (tag: ITag) => !Object.keys(tag).includes("parent")
    ) as ITag[],
    location: "card",
    required: true,
  },
  tags: {
    label: "Tags",
    type: "multipleTag",
    enum: tagList?.filter((tag: ITag) =>
      parentList?.length ? parentList.includes(tag?.parent?.uid as never) : true
    ) as ITag[],
    location: "card",
    required: false,
  },
  doi: {
    label: "DOI",
    type: "text",
    location: "card",
    required: true,
  },
  title: {
    label: "Title",
    type: "text",
    location: "card",
    required: true,
  },
  conclusion: {
    label: "Conclusion",
    type: "textarea",
    location: "card",
    array: true,
    required: true,
  },
  journal: {
    label: "Journal",
    type: "search",
    enum: journalList?.map((journal: IJournal) => journal.name),
    location: "card",
    required: true,
  },
  primary_author: {
    label: "Primary author",
    type: "text",
    location: "card",
    required: true,
  },
  authors: {
    label: "Authors",
    type: "text",
    location: "card",
    array: true,
    required: false,
  },
  edito: {
    label: "Edito",
    type: "text",
    location: "publication",
    required: false,
  },
  background: {
    label: "Background",
    type: "textarea",
    location: "publication",
    required: true,
  },
  objectives: {
    label: "Objectives",
    type: "textarea",
    location: "publication",
    array: true,
    required: true,
  },
  methodology: {
    label: "Methodology",
    type: "textarea",
    location: "publication",
    required: true,
  },
  results: {
    label: "Results",
    type: "textarea",
    location: "publication",
    array: true,
    required: true,
  },
  limitations: {
    label: "Limitations",
    type: "textarea",
    location: "publication",
    required: true,
  },
  disclosure: {
    label: "Disclosure",
    type: "textarea",
    location: "publication",
    required: true,
  },
  keywords: {
    label: "Keywords",
    type: "text",
    location: "publication",
    array: true,
    required: false,
  },
  article_type: {
    label: "Article type",
    type: "select",
    enum: [
      "Case Report",
      "Clinical study",
      "Commentary",
      "Guidelines",
      "Letter/Editorial",
      "Meta-analysis",
      "Original research",
      "Perspective",
      "Review",
      "Short communication",
      "Study Protocol",
      "Systematic review and meta-analysis",
      "Technical report",
    ],
    location: "publication",
    required: true,
  },
});

export const updateArticleMapping = (
  tagList?: ITag[],
  parentList?: string[] | []
): IMapping => ({
  label: "Tags",
  type: "multipleTag",
  enum: tagList?.filter((tag: ITag) =>
    parentList?.length ? parentList.includes(tag?.parent?.uid as never) : true
  ) as ITag[],
  location: "card",
  required: false,
});

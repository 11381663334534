import { useContext, useEffect, useState } from "react";
import { Button, Divider, Select, Table, Tag } from "antd";
import { getRoomsList } from "~/services";
import { IRoom, SupportedLanguage } from "~/model";
import LoadingLayout from "./shared/LoadingLayout";
import { ColumnsType } from "antd/lib/table";
import { Flex } from "./shared/global";
import { GlobalContext } from "~/context/global.context";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AppstoreAddOutlined } from "@ant-design/icons";
import { languageLabel } from "~/utils/helpers";

const RoomsList = () => {
  const { organisationList } = useContext(GlobalContext);
  const [roomsList, setRoomsList] = useState<IRoom[] | null>(null);
  const [selectedOrganisation, setSelectedOrganisation] = useState<
    string | undefined
  >(undefined);
  const location = useLocation();

  useEffect(() => {
    const fetchRoomsList = async () => {
      const rooms = await getRoomsList(selectedOrganisation);
      setRoomsList(rooms);
    };

    fetchRoomsList();
  }, [selectedOrganisation]);

  if (!roomsList) return <LoadingLayout />;

  const columns: ColumnsType<IRoom> = [
    {
      title: "Room Name",
      dataIndex: "name",
      key: "name",
      render: (text, room) => (
        <Link to={`${location.pathname}/${room.id}`}>{text}</Link>
      ),
    },
    {
      title: "Organisation",
      dataIndex: ["organisation", "name"],
      key: "organisation",
    },
    {
      title: "Languages",
      dataIndex: "allowedLanguages",
      key: "allowedLanguages",
      render: (languages: SupportedLanguage[]) =>
        languages.map((lang) => languageLabel[lang]).join(", ") || "N/A",
    },
    {
      title: "Status",
      dataIndex: "isPublic",
      key: "isPublic",
      render: (isPublic) =>
        isPublic ? (
          <Tag color='green'>{"Public"}</Tag>
        ) : (
          <Tag color='purple'>{"Private"}</Tag>
        ),
    },
  ];

  return (
    <div className='basic-layout'>
      <Flex justify='space-between' align='center'>
        <h1 style={{ margin: 0 }}>Rooms</h1>
        <div>
          <Flex align='center'>
            <Select
              allowClear
              placeholder='Select Organisation'
              style={{ width: 200 }}
              options={organisationList.map((org) => ({
                label: org.name,
                value: org._id,
              }))}
              onChange={(value) => setSelectedOrganisation(value)}
            />
            <Divider type='vertical' />
            <Link to='/sensitive-data/rooms/create'>
              <Button type='primary' icon={<AppstoreAddOutlined />}>
                Create Room
              </Button>
            </Link>
          </Flex>
        </div>
      </Flex>
      <Divider />
      <Table
        columns={columns}
        bordered
        pagination={{ pageSize: 50 }}
        dataSource={roomsList.map((room) => ({
          ...room,
          key: room.id,
        }))}
      />
    </div>
  );
};

export default RoomsList;

export const Flex = (props: {
  justify?: "start" | "end" | "center" | "space-between" | "space-around";
  align?: "start" | "end" | "center";
  flexDirection?: "row" | "column";
  gap?: number;
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => (
  <div
    style={{
      display: "flex",
      justifyContent: props.justify || "start",
      alignItems: props.align || "start",
      gap: props.gap || 0,
      flexDirection: props.flexDirection || "row",
      width: "100%",
      ...props.style,
    }}
  >
    {props.children}
  </div>
);

export const Spacer = (props: { height?: number }) => (
  <div style={{ height: `${props.height || 24}px`, width: "100%" }} />
);

import React, { useCallback, useContext, useEffect, useState } from "react";
import "antd/dist/antd.less";
import { Button, Layout, Menu, Modal } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { GlobalContext } from "~/context/global.context";
import {
  BankOutlined,
  CalendarOutlined,
  DeploymentUnitOutlined,
  FilePdfOutlined,
  FileSearchOutlined,
  FileTextOutlined,
  FlagOutlined,
  FolderOpenOutlined,
  HomeOutlined,
  KeyOutlined,
  LeftOutlined,
  LockOutlined,
  NotificationOutlined,
  PictureOutlined,
  PoweroffOutlined,
  ReadOutlined,
  RightOutlined,
  RobotOutlined,
  ShareAltOutlined,
  ShopOutlined,
  SolutionOutlined,
  TableOutlined,
  TagsOutlined,
  UnorderedListOutlined,
  UserOutlined,
  VideoCameraOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import JuisciLogo from "~/assets/logo-base.svg";
import LoadingLayout from "./LoadingLayout";
import { IUserRole, PermissionLabel } from "~/model";
import { checkPermission } from "~/utils/helpers";
import { NavLink } from "react-router-dom";
import { ItemType } from "antd/lib/menu/hooks/useItems";

const AppLayout = ({ children }: { children?: React.ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user, updateUser } = useContext(GlobalContext);

  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("_siderCollapsed") === "true" || false
  );
  const [defaultSelectedMenu, setDefaultSelectedMenu] =
    useState<Array<string> | null>(null);
  const [defaultSelectedSubMenu, setDefaultSelectedSubMenu] =
    useState<Array<string> | null>(null);

  const getDefaultSelectedKeys = (type: "menu" | "submenu") => {
    const path = location.pathname;
    const pathArray = path.split("/");

    if (type === "menu") setDefaultSelectedMenu([pathArray[1]]);
    else setDefaultSelectedSubMenu([`${pathArray[1]}-${pathArray[2]}`]);
  };

  const fetchUser = useCallback(async () => {
    if (!user) {
      try {
        updateUser();
      } catch {
        navigate("/");
      }
    }
  }, [navigate, updateUser, user]);

  const handleLogout = () => {
    Modal.confirm({
      title: "Are you sure you want to logout?",
      onOk: () => {
        updateUser(true);
        window.location.href = "/";
      },
    });
  };

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    localStorage.setItem("_siderCollapsed", collapsed.toString());
  }, [collapsed]);

  useEffect(() => {
    getDefaultSelectedKeys("menu");
    getDefaultSelectedKeys("submenu");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (!user && window.location.pathname !== "/") return <LoadingLayout />;
  if (!user) return children;

  const menuItems = [
    {
      key: "content-sources",
      title: "Content Sources",
      link: "/content-sources",
      icon: <ShareAltOutlined />,
      disabled: !checkPermission(user, PermissionLabel.Sources),
      submenus: [
        {
          key: "content-sources-crawling",
          title: "Crawling",
          link: "/content-sources/crawling",
          icon: <FileSearchOutlined />,
          disabled: false,
        },
        {
          key: "content-sources-parsing",
          title: "Parsing",
          link: "/content-sources/parsing",
          icon: <FilePdfOutlined />,
        },
      ],
    },
    {
      key: "content-management",
      title: "Content Management",
      link: "/content-management",
      icon: <TableOutlined />,
      disabled: !checkPermission(user, PermissionLabel.Content),
      submenus: [
        {
          key: "content-management-article",
          title: "Articles",
          link: "/content-management/article",
          icon: <FileTextOutlined />,
        },
        {
          key: "content-management-nectar",
          title: "Nectars",
          link: "/content-management/nectar",
          icon: <RobotOutlined />,
        },
        {
          key: "content-management-video",
          title: "Videos",
          link: "/content-management/video",
          icon: <VideoCameraOutlined />,
        },
        {
          key: "content-management-infographic",
          title: "Infographics",
          link: "/content-management/infographic/",
          icon: <PictureOutlined />,
        },
        {
          key: "content-management-playlist",
          title: "Playlists",
          link: "/content-management/playlist",
          icon: <UnorderedListOutlined />,
        },
      ],
    },
    {
      key: "reference-data",
      title: "Reference Data",
      link: "/reference-data",
      icon: <FolderOpenOutlined />,
      disabled: !checkPermission(user, PermissionLabel.Referential),
      submenus: [
        {
          key: "reference-data-company",
          title: "Companies",
          link: "/reference-data/company",
          icon: <ShopOutlined />,
        },
        {
          key: "reference-data-congress",
          title: "Congresses",
          link: "/reference-data/congress",
          icon: <CalendarOutlined />,
        },
        {
          key: "reference-data-tag",
          title: "Medical specialties",
          link: "/reference-data/tag",
          icon: <TagsOutlined />,
        },
        {
          key: "reference-data-journal",
          title: "Journals",
          link: "/reference-data/journal",
          icon: <ReadOutlined />,
        },
        {
          key: "reference-data-profession",
          title: "Professions",
          link: "/reference-data/profession",
          icon: <SolutionOutlined />,
          disabled: true,
        },
      ],
    },
    {
      key: "sensitive-data",
      title: "Sensitive Data",
      link: "/sensitive-data",
      icon: <WarningOutlined />,
      disabled: !checkPermission(user, PermissionLabel.SensitiveData),
      submenus: [
        {
          key: "sensitive-data-organisations",
          title: "Organisations",
          link: "/sensitive-data/organisations",
          icon: <BankOutlined />,
        },
        {
          key: "sensitive-data-rooms",
          title: "Rooms",
          link: "/sensitive-data/rooms",
          icon: <HomeOutlined />,
        },
        {
          key: "sensitive-data-users",
          title: "Users",
          link: "/sensitive-data/users",
          icon: <UserOutlined />,
        },
        {
          key: "sensitive-data-notifications",
          title: "Notifications",
          link: "/sensitive-data/notifications",
          icon: <NotificationOutlined />,
        },
        {
          key: "sensitive-data-announcements",
          title: "Pop-up Announcements",
          link: "/sensitive-data/announcements",
          icon: <FlagOutlined />,
        },
        {
          key: "sensitive-data-features-manager",
          title: "Features Manager",
          link: "/sensitive-data/features-manager",
          icon: <DeploymentUnitOutlined />,
        },
      ],
    },
    {
      key: "super-admin",
      title: "Super Admin",
      link: "/super-admin",
      disabled: !user.roles.includes(IUserRole.SUPER_ADMIN),
      icon: <KeyOutlined />,
      submenus: [
        {
          key: "super-admin-roles-permissions",
          title: "Roles & Permissions",
          link: "/super-admin/roles-permissions",
          icon: <LockOutlined />,
        },
      ],
    },
  ];

  const menuData: ItemType[] = (
    user?.roles?.includes(IUserRole.SUPER_ADMIN)
      ? menuItems
      : menuItems.filter((el) => el.key !== "superAdmin")
  ).map((item) => ({
    label: item.title,
    key: item.key,
    icon: item.icon,
    disabled: item.disabled,
    children: item.submenus.map((submenu) => ({
      label: <NavLink to={submenu.link}>{submenu.title}</NavLink>,
      key: submenu.key,
      icon: submenu.icon,
    })),
  })) as ItemType[];

  menuData?.push({
    key: "logout",
    label: "Logout",
    icon: <PoweroffOutlined />,
    danger: true,
    className: "logout-button",
    onClick: handleLogout,
  } as ItemType);

  return (
    <StyledLayout className='app-layout'>
      <Layout.Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className='sider-menu'
        width={256}
      >
        <img
          src={JuisciLogo}
          alt='Juisci'
          className={`juisci-logo ${collapsed ? "collapsed" : ""}`}
        />
        <Menu
          theme='dark'
          className='sider-menu-menu'
          mode='inline'
          onOpenChange={(openKeys) => setDefaultSelectedMenu(openKeys)}
          openKeys={(!collapsed && defaultSelectedMenu) || undefined}
          selectedKeys={defaultSelectedSubMenu || undefined}
          onSelect={({ selectedKeys }) =>
            setDefaultSelectedSubMenu(selectedKeys)
          }
          items={menuData}
        />
        <Button
          className='collapse-button'
          shape='circle'
          size='small'
          type='primary'
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? <RightOutlined /> : <LeftOutlined />}
        </Button>
      </Layout.Sider>
      <Layout className='site-layout'>
        <Layout.Content
          style={{
            height: "100vh",
            overflow: "auto",
          }}
        >
          {children}
        </Layout.Content>
      </Layout>
    </StyledLayout>
  );
};

export default AppLayout;

const StyledLayout = styled(Layout)`
  .juisci-logo {
    height: 24px;
    margin: 24px auto;
    width: 256px;
    transition: all 0.2s;

    &.collapsed {
      width: 80px;
      padding: 0 16px;
    }
  }

  .sider-menu {
    position: relative;
    display: block;
    width: 256px;
    background-color: #29374b;

    a.active {
      color: #212121 !important;
    }

    .sider-menu-menu {
      background-color: #29374b;
      height: calc(100vh - 160px) !important;
      overflow-y: hidden;
    }

    .logout-button {
      position: absolute;
      bottom: 24px;
      background: transparent !important;
      color: red !important;

      .ant-menu-title-content,
      * {
        color: red !important;
      }
    }

    .collapse-button {
      position: absolute;
      bottom: 128px;
      right: -12px;
    }
  }
`;

import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Drawer, Form, Input, Select, Space } from "antd";
import {
  BankOutlined,
  CalendarOutlined,
  FilterFilled,
  FilterOutlined,
  GlobalOutlined,
  PlusSquareOutlined,
  TagOutlined,
} from "@ant-design/icons";
import { GlobalContext } from "~/context/global.context";
import {
  languageOptions,
  pathToSlug,
  searchFilterOptions,
} from "~/utils/helpers";
import styled from "styled-components";
import { SupportedLanguage } from "~/model";
import { Flex } from "./global";
import { useLocation } from "react-router-dom";
import { DefaultOptionType } from "antd/lib/select";

const FILTERS_EXCEPTIONS = ["limit", "offset", "search"];

export type FiltersDrawerValuesModel = {
  limit?: number;
  offset?: number;
  search?: string;
  languages?: SupportedLanguage[];
  journals?: string[];
  medicalSpecialties?: string[];
  rooms?: string[];
  tags?: string[];
  owners?: string[];
  companies?: string[];
  status?: "draft" | "published" | "all";
  videoFormat?: "article" | "story";
  isPublic?: boolean;
};

export const LOCAL_STORAGE_FILTERS_KEY = "savesFiltersDrawer";

const FiltersDrawer = ({
  children,
  defaultValues,
  fields,
  onChange,
}: {
  children?: React.ReactNode;
  defaultValues?: FiltersDrawerValuesModel;
  fields?: Array<keyof FiltersDrawerValuesModel> | string[];
  onChange: (filters: FiltersDrawerValuesModel) => void;
}) => {
  const location = useLocation();
  const { organisationList, roomList, tagList, journalList } =
    useContext(GlobalContext);
  const [visible, setVisible] = useState(false);
  const [innerFilters, setInnerFilters] =
    useState<FiltersDrawerValuesModel | null>({
      ...defaultValues,
    });
  const [searchValue, setSearchValue] = useState<string | undefined>(
    innerFilters?.search
  );
  const [form] = Form.useForm();

  const pathSlug = pathToSlug(location.pathname);

  const handleClose = () => {
    setVisible(false);
  };

  const handleApplyFilters = () => {
    const filters = form.getFieldsValue();
    const newFilters = { ...innerFilters, ...filters };
    setInnerFilters(newFilters);
    onChange(newFilters);
    setVisible(false);
  };

  const handleSearch = (value: string) => {
    const search = value.length ? value : undefined;

    form.setFieldValue("search", search);
    setInnerFilters({ ...innerFilters, search });
    onChange({ ...innerFilters, search });
  };

  const handleSaveFilters = useCallback(() => {
    const saves = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_FILTERS_KEY) || "{}"
    );
    saves[pathSlug] = innerFilters;
    delete saves[pathSlug]?.["limit"];
    delete saves[pathSlug]?.["offset"];
    localStorage.setItem(LOCAL_STORAGE_FILTERS_KEY, JSON.stringify(saves));
  }, [innerFilters, pathSlug]);

  useEffect(() => {
    function handleClearFilters() {
      const saves = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_FILTERS_KEY) || "{}"
      );
      form.resetFields();
      onChange(defaultValues || form.getFieldsValue());
      setVisible(false);
      saves[pathSlug] = null;
      localStorage.setItem(LOCAL_STORAGE_FILTERS_KEY, JSON.stringify(saves));
    }

    if (innerFilters === null) handleClearFilters();

    return () => {
      handleSaveFilters();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerFilters]);

  useEffect(() => {
    function loadFilters() {
      const saves = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_FILTERS_KEY) || "{}"
      );

      const filters = saves[pathSlug];
      delete filters?.limit;
      delete filters?.offset;

      if (filters) {
        form.setFieldsValue(filters);
        onChange(filters);
        setInnerFilters(filters);

        if (filters?.search) {
          setSearchValue(filters.search);
          handleSearch(filters.search);
        }
      }
    }

    loadFilters();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filtersCount = Object.values(innerFilters || {})
    .filter((val) => !!val && Array.isArray(val) && val.length > 0)
    .filter(
      (val) => !!val && !FILTERS_EXCEPTIONS.includes(val as string)
    ).length;

  const noFilters = !innerFilters || filtersCount === 0;

  return (
    <div style={{ width: "100%" }}>
      <Flex align='center' gap={16} justify='end' style={{ width: "100%" }}>
        {!fields || fields?.includes("search") ? (
          <Input.Search
            allowClear
            placeholder='Search'
            style={{ width: "100%" }}
            value={searchValue}
            onSearch={handleSearch}
            onChange={(e) => {
              if (e.target.value?.length === 0) handleSearch(e.target.value);
              setSearchValue(e.target.value);
            }}
          />
        ) : (
          <div />
        )}
        <Button
          type={noFilters ? "default" : "primary"}
          onClick={() => setVisible(!visible)}
          icon={noFilters ? <FilterOutlined /> : <FilterFilled />}
        >
          {noFilters ? "Show filters" : `Filters (${filtersCount})`}
        </Button>
      </Flex>

      <StyledDrawer
        title='Filters'
        placement='right'
        closable={true}
        onClose={handleClose}
        open={visible}
        extra={
          <Space>
            <Button onClick={() => setInnerFilters(null)}>{"Clear"}</Button>
            <Button type='primary' onClick={handleApplyFilters}>
              {"Apply"}
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          initialValues={{
            ...innerFilters,
            ...defaultValues,
          }}
        >
          {(!fields || fields?.includes("languages")) && (
            <>
              <h5>
                <GlobalOutlined />
                {"Languages"}
              </h5>
              <Form.Item name='languages'>
                <Select
                  allowClear
                  mode='multiple'
                  placeholder='Filter by languages'
                  options={languageOptions}
                />
              </Form.Item>
            </>
          )}

          {(!fields ||
            ["owner", "rooms", "isPublic"].some((el) =>
              fields?.includes(el as keyof FiltersDrawerValuesModel)
            )) && (
            <h5>
              <BankOutlined />
              {"Organization"}
            </h5>
          )}
          {(!fields || fields?.includes("owners")) && (
            <Form.Item name='owners'>
              <Select
                allowClear
                placeholder='Filter by owners'
                mode='multiple'
                filterOption={searchFilterOptions}
                options={organisationList
                  .map((org) => ({
                    label: org.name,
                    value: org._id,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label) || 0)}
                onChange={(owners) => {
                  setInnerFilters({ ...innerFilters, owners });
                  if (owners.length) form.resetFields(["rooms"]);
                }}
              />
            </Form.Item>
          )}

          {(!fields || fields?.includes("rooms")) && (
            <Form.Item name='rooms'>
              <Select
                allowClear
                mode='multiple'
                placeholder='Filter by rooms'
                filterOption={searchFilterOptions}
                options={roomList
                  ?.filter((room) =>
                    innerFilters?.owners
                      ? innerFilters.owners.some(
                          (o) => o === room.organisation._id
                        )
                      : true
                  )
                  ?.map((room) => ({
                    label: room.name,
                    value: room.id,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label) || 0)}
              />
            </Form.Item>
          )}

          {(!fields || fields?.includes("isPublic")) && (
            <Form.Item name='isPublic'>
              <Select
                allowClear
                placeholder='Public or Private'
                options={[
                  { label: "✅ Public", value: true },
                  { label: "🔐 Private", value: false },
                ]}
              />
            </Form.Item>
          )}

          {(!fields || fields?.includes("status")) && (
            <>
              <h5>
                <CalendarOutlined />
                {"Status"}
              </h5>
              <Form.Item name='status'>
                <Select
                  allowClear
                  placeholder='Draft or Published'
                  options={[
                    { label: "Published", value: "published" },
                    { label: "Draft", value: "draft" },
                  ]}
                />
              </Form.Item>
            </>
          )}

          {(!fields ||
            ["medicalSpecialty", "tags", "journal"].some((el) =>
              fields?.includes(el as keyof FiltersDrawerValuesModel)
            )) && (
            <h5>
              <TagOutlined />
              {"Content Details"}
            </h5>
          )}
          {(!fields || fields?.includes("medicalSpecialties")) && (
            <Form.Item name='medicalSpecialties'>
              <Select
                allowClear
                placeholder='Medical Specialties'
                mode='multiple'
                filterOption={searchFilterOptions}
                options={tagList
                  .filter((tag) => !tag.parent)
                  .map((tag) => ({
                    label: tag.translations.en,
                    value: tag._id,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label) || 0)}
                onChange={(medicalSpecialties) => {
                  setInnerFilters({ ...innerFilters, medicalSpecialties });
                  if (medicalSpecialties.length) form.resetFields(["tags"]);
                }}
              />
            </Form.Item>
          )}

          {(!fields || fields?.includes("tags")) && (
            <Form.Item name='tags'>
              <Select
                allowClear
                placeholder='Filter by tags'
                mode='multiple'
                filterOption={(input, option) => {
                  const customOption = option as DefaultOptionType;
                  customOption["label"] = customOption["name"];
                  return searchFilterOptions(input, customOption);
                }}
                options={tagList
                  .filter(
                    (tag) =>
                      !!tag.parent &&
                      (innerFilters?.medicalSpecialties?.length
                        ? innerFilters.medicalSpecialties.some(
                            (ms) => ms === tag.parent?._id
                          )
                        : true)
                  )
                  .map((tag) => ({
                    label: (
                      <span>
                        {tag.translations.en}{" "}
                        <small>{`(${tag.parent?.translations.en})`}</small>
                      </span>
                    ),
                    name: tag.translations.en,
                    value: tag._id,
                  }))
                  .sort((a, b) => a.name.localeCompare(b.name) || 0)}
              />
            </Form.Item>
          )}

          {(!fields || fields.includes("journals")) && (
            <Form.Item name='journals'>
              <Select
                allowClear
                mode='multiple'
                placeholder='Filter by journal'
                filterOption={searchFilterOptions}
                options={journalList
                  .map((journal) => ({
                    label: journal.name,
                    value: journal._id,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label) || 0)}
              />
            </Form.Item>
          )}

          {!!children && (
            <h5>
              <PlusSquareOutlined />
              {"Additional Filters"}
            </h5>
          )}
          {children}
        </Form>
      </StyledDrawer>
    </div>
  );
};

export default FiltersDrawer;

const StyledDrawer = styled(Drawer)`
  h5 {
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;

    margin: 16px 0;
  }
`;

import { useContext, useEffect, useState } from "react";
import { Flex, Spacer } from "./shared/global";
import { useNavigate, useParams } from "react-router-dom";
import {
  createInvite,
  createRoom,
  deleteArticleFromRoom,
  deleteContentFromRoom,
  deleteInvite,
  deletePlaylistFromRoom,
  getProfessionList,
  getRoomById,
  listInvites,
  postArticleToRoom,
  postConfigureRoom,
  postContentToRoom,
  postPlaylistToRoom,
  postRoomLogo,
  searchArticlesV2,
  searchPlaylists,
  searchVideos,
} from "~/services";
import {
  ContentFormatsEnum,
  IArticle,
  IContent,
  IInvite,
  IPlaylist,
  IProfession,
  IRoom,
  RoomType,
} from "~/model";
import LoadingLayout from "./shared/LoadingLayout";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
  Upload,
  message,
  notification,
} from "antd";
import {
  AppstoreAddOutlined,
  CloseOutlined,
  LeftOutlined,
  PlusOutlined,
  SaveOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { GlobalContext } from "~/context/global.context";
import { languageOptions, searchFilterOptions } from "~/utils/helpers";
import { UploadChangeParam, UploadFile, UploadProps } from "antd/lib/upload";
import { IConfigureRoomDto, ICreateRoomDto } from "~/model/dto/room-dto";

const env = import.meta.env.VITE_APP_ENV;

const RoomEdition = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { organisationList, tagList } = useContext(GlobalContext);
  const [room, setRoom] = useState<IRoom | null>(null);
  const [professions, setProfessions] = useState<IProfession[] | null>(null);
  const [innerSpecialties, setInnerSpecialties] = useState<string[] | null>(
    null
  );

  const [form] = Form.useForm();

  useEffect(() => {
    const fetchRoomData = async () => {
      if (!params.id) return;

      const roomData = await getRoomById(params.id);

      setInnerSpecialties([
        roomData.mainSpecialty?._id,
        ...roomData.medicalSpecialties.map((spe) => spe._id),
      ]);

      setRoom(roomData);
    };

    fetchRoomData();
  }, [params.id]);

  useEffect(() => {
    const fetchProfessions = async () => {
      const { docs: professionList } = await getProfessionList({ limit: 100 });

      setProfessions(professionList);
    };

    fetchProfessions();
  }, []);

  const handleSubmit = async (values: IConfigureRoomDto | ICreateRoomDto) => {
    if (params.id) {
      // Update room
      console.log(values);

      try {
        await postConfigureRoom(params.id, values as IConfigureRoomDto);

        message.success("Room updated successfully!");
      } catch (error) {
        message.error("An error occured while updating the room");
        throw error;
      }
    } else {
      // Create room
      try {
        const res = await createRoom(values as ICreateRoomDto);
        message.success("Room created successfully!");
        navigate(`/sensitive-data/rooms/${res.id}`);
      } catch (error) {
        message.error("An error occured while creating the room");
        throw error;
      }
    }
  };

  const handleChangeMainSpecialty = (value: string) => {
    setInnerSpecialties(
      innerSpecialties ? [...innerSpecialties, value] : [value]
    );

    form.setFieldsValue({
      medicalSpecialties: form
        .getFieldValue("medicalSpecialties")
        ?.filter((spe: string) => spe !== value),
      tags: [],
    });
  };

  const handleChangeMedicalSpecialties = (values: string[]) => {
    setInnerSpecialties(
      innerSpecialties
        ? [...new Set([...innerSpecialties, ...values])]
        : [...values]
    );

    form.setFieldsValue({
      tags: [],
    });
  };

  if (!room && params.id) return <LoadingLayout />;

  const initialRoomValues = {
    ...room,
    organisation: room?.organisation._id,
    professions: room?.professions?.map((prof) => prof._id),
    mainSpecialty: room?.mainSpecialty?._id,
    medicalSpecialties: room?.medicalSpecialties?.map((spe) => spe._id),
    tags: room?.tags?.map((tag) => tag._id),
  };

  return (
    <div className='basic-layout'>
      <Button
        type='link'
        onClick={() => navigate(-1)}
        icon={<LeftOutlined />}
        style={{ padding: 0, marginBottom: 8 }}
      >
        {"Back"}
      </Button>
      <Spacer height={12} />
      <Flex justify='space-between' align='center'>
        <h1 style={{ margin: 0 }}>{`${room ? room.name : "Create"} - Room`}</h1>
        <Button
          type='primary'
          icon={params.id ? <SaveOutlined /> : <AppstoreAddOutlined />}
          onClick={() => form.submit()}
        >
          {params.id ? "Save settings" : "Create Room"}
        </Button>
      </Flex>
      <Spacer />
      <Form
        form={form}
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={initialRoomValues}
      >
        <Tabs color='primary' type='card'>
          <Tabs.TabPane key={0} tab='Informations'>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card title='Room Informations'>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item name='name' label='Room Name' required>
                        <Input
                          placeholder='Room Name'
                          value={room?.name}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name='organisation'
                        label='Organisation'
                        required
                      >
                        <Select
                          placeholder='Select an organisation'
                          disabled={!organisationList.length || !!room}
                          options={organisationList.map((org) => ({
                            label: org.name,
                            value: org._id,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name='type' label='Room Type' required>
                        <Select
                          placeholder='Select a room type'
                          disabled={!!room}
                          options={Object.values(RoomType).map((type) => ({
                            label:
                              type === RoomType.Default ? "In-App Room" : type,
                            value: type,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name='isPublic' label='Accessibility' required>
                        <Select placeholder='Accessibility'>
                          <Select.Option value={true}>
                            {"✅ Public"}
                          </Select.Option>
                          <Select.Option value={false}>
                            {"🔐 Private"}
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24}>
                <RoomLogoUploader room={room} />
              </Col>
              <Col span={24}>
                <Card title='Room Settings'>
                  <Row gutter={[16, 0]}>
                    <Col span={24}>
                      <Form.Item
                        name='allowedLanguages'
                        label='Allowed Languages'
                      >
                        <Select
                          mode='multiple'
                          placeholder='Allowed Languages'
                          options={languageOptions}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name='professions' label='Professions'>
                        <Select
                          showSearch
                          allowClear
                          mode='multiple'
                          placeholder={"Professions"}
                          style={{ width: "100%" }}
                          filterOption={searchFilterOptions}
                          options={professions?.map((prof) => ({
                            label: prof.translations.en,
                            value: prof._id,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name='mainSpecialty' label='Main Specialty'>
                        <Select
                          showSearch
                          allowClear
                          disabled={!tagList.length}
                          placeholder={"Main specialty"}
                          style={{ width: "100%" }}
                          filterOption={searchFilterOptions}
                          onChange={handleChangeMainSpecialty}
                          options={tagList
                            .filter((el) => !el.parent)
                            ?.map((spe) => ({
                              label: spe.translations.en,
                              value: spe._id,
                            }))}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name='medicalSpecialties'
                        label='Medical Specialties'
                      >
                        <Select
                          showSearch
                          allowClear
                          mode='multiple'
                          disabled={!tagList.length}
                          placeholder={"Medical Specialties"}
                          style={{ width: "100%" }}
                          filterOption={searchFilterOptions}
                          onChange={handleChangeMedicalSpecialties}
                          options={tagList
                            ?.filter((el) => !el.parent)
                            ?.filter(
                              (el) =>
                                el?._id !== form.getFieldValue("mainSpecialty")
                            )
                            ?.map((spe) => ({
                              label: spe.translations.en,
                              value: spe._id,
                            }))}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name='tags' label='Tags'>
                        <Select
                          showSearch
                          allowClear
                          mode='multiple'
                          placeholder={"Tags"}
                          style={{ width: "100%" }}
                          filterOption={searchFilterOptions}
                          options={tagList
                            .filter((el) => !!el.parent)
                            .filter(
                              (el) =>
                                (el.parent?._id &&
                                  innerSpecialties?.includes(el.parent?._id)) ||
                                innerSpecialties?.includes(el?._id)
                            )
                            ?.map((tag) => ({
                              label: `${tag.translations.en} (${tag.parent?.translations.en})`,
                              value: tag._id,
                            }))}
                        />
                      </Form.Item>
                    </Col>
                    <Divider />
                    <Col span={8}>
                      <Form.Item
                        name='discoveryFilter'
                        label='Discovery Filter'
                      >
                        <Select
                          placeholder='Discovery Filter'
                          options={[
                            {
                              label: "👍 Yes",
                              value: true,
                            },
                            {
                              label: "👎 No",
                              value: false,
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name='primaryColor'
                        label='Primary Color'
                        tooltip='Background color in-app for DISCOVERY PAGE.'
                      >
                        <Input placeholder='#FFFFFF' />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name='secondaryColor'
                        label='Secondary Color'
                        tooltip='Background color in-app for CONGRESS PAGE.'
                      >
                        <Input placeholder='#FFFFFF' />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane key={1} disabled={!room} tab='Content'>
            {room && (
              <Col span={24}>
                <Card title='Room Contents & Playlists'>
                  <Tabs>
                    <Tabs.TabPane key='articlesTab' tab='Articles'>
                      <RoomEditionArticles articles={room.articles} />
                    </Tabs.TabPane>
                    <Tabs.TabPane key='contentTabs' tab='Contents'>
                      <RoomEditionContents contents={room.contents} />
                    </Tabs.TabPane>
                    <Tabs.TabPane key='playlistsTabs' tab='Playlists'>
                      <RoomEditionPlaylists playlists={room.playlists} />
                    </Tabs.TabPane>
                  </Tabs>
                </Card>
              </Col>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane key={2} tab='Invites'>
            {room && <InviteLinkSection room={room} />}
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </div>
  );
};

export default RoomEdition;

const RoomLogoUploader = ({ room }: { room: IRoom | null }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);

  const handleChangeImage: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    const file = info.file.originFileObj as File;

    setImageFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const handleImageUpload = async () => {
    if (!imageFile || !room) return;

    setLoading(true);
    try {
      await postRoomLogo(room.id, imageFile);
      setImageFile(null);
      notification.success({
        message: "Image uploaded successfully!",
        description: "The image has been uploaded to the room",
      });
    } catch (error) {
      notification.error({
        message: "An error occured while uploading the image",
        description: "Please try again later",
      });

      throw error;
    }
    setLoading(false);
  };

  useEffect(() => {
    if (room && room.logo) setImageUrl(room.logo.url);
  }, [room]);

  if (!room) return null;

  return (
    <Card title='Room Image'>
      <Row gutter={16}>
        <Col span={14}>
          <Flex gap={16}>
            <Upload
              accept='.png, .jpg, .jpeg, .svg'
              maxCount={1}
              showUploadList={false}
              onChange={handleChangeImage}
              customRequest={(payload) => {
                if (payload.onSuccess) payload.onSuccess("ok");
              }}
              style={{
                display: "block",
                width: "100%",
                height: 200,
                backgroundColor: "blue",
              }}
            >
              <Button
                block
                icon={<UploadOutlined />}
                style={{
                  display: "block",
                  width: "100%",
                }}
              >
                Upload Logo
              </Button>
            </Upload>
            <Button
              block
              type='primary'
              loading={loading}
              disabled={!imageFile}
              onClick={handleImageUpload}
            >
              {"Confirm"}
            </Button>
          </Flex>
        </Col>
        <Col span={10}>
          {imageUrl ? (
            <img
              src={imageUrl || ""}
              style={{
                height: "auto",
                width: "100%",
                maxHeight: 150,
                objectFit: "contain",
                objectPosition: "center",
              }}
              alt='Room Logo'
            />
          ) : null}
        </Col>
      </Row>
    </Card>
  );
};

const InviteLinkSection = ({ room }: { room: IRoom }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [innerInvites, setInnerInvites] = useState<IInvite[] | null>(null);

  const roomId = room.id;
  const organisationId = room.organisation._id;

  useEffect(() => {
    const fetchInvites = async () => {
      if (!organisationId) return;

      try {
        const invites = await listInvites(organisationId);
        setInnerInvites(invites);
      } catch (error) {
        notification.error({
          message: "An error occured while fetching the invites",
          description: "Please try again later",
        });
        throw error;
      }
    };

    fetchInvites();
  }, [organisationId]);

  const handleInviteCreation = async () => {
    if (!roomId) return;

    setLoading(true);

    try {
      const createdInvite = await createInvite(roomId);

      setInnerInvites(
        innerInvites ? [...innerInvites, createdInvite] : [createdInvite]
      );

      notification.success({
        message: "Invite created successfully!",
        description: "The invite has been created and added to the room",
      });
    } catch (error) {
      notification.error({
        message: "An error occured while creating the invite",
        description: "Please try again later",
      });
      throw error;
    }
    setLoading(false);
  };

  const handleInviteDeletion = async (inviteCodeId: string) => {
    if (!organisationId) return;

    setLoading(false);
    try {
      await deleteInvite(inviteCodeId);
      const invites = await listInvites(organisationId);
      setInnerInvites(invites);

      notification.success({
        message: "Invite deleted successfully!",
        description: "The invite has been removed from the room",
      });
    } catch (error) {
      notification.error({
        message: "An error occured while deleting the invite",
        description: "Please try again later",
      });
      throw error;
    }

    setLoading(false);
  };

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Card title={"List invite links"}>
          <Row gutter={[16, 16]}>
            {innerInvites ? (
              innerInvites.map((invite) => (
                <Col span={24} key={invite._id}>
                  <Card key={invite.code} style={{ marginBottom: "20px" }}>
                    <p>
                      <strong>Code: </strong>
                      <a
                        href={
                          env === "production"
                            ? `https://app.juisci.com/invite/${invite.code}`
                            : `https://dev.juisci.com/invite/${invite.code}`
                        }
                      >
                        {env === "production"
                          ? `https://app.juisci.com/invite/${invite.code}`
                          : `https://dev.juisci.com/invite/${invite.code}`}
                      </a>
                      <br />
                      <strong>Created at: </strong>
                      {new Date(invite.createdAt).toLocaleDateString()}
                    </p>
                    <Button
                      danger
                      type='primary'
                      loading={loading}
                      style={{
                        float: "right",
                      }}
                      onClick={() => handleInviteDeletion(invite._id)}
                    >
                      Remove
                    </Button>
                  </Card>
                </Col>
              ))
            ) : (
              <Spin />
            )}
          </Row>
        </Card>
      </Col>
      <Col span={8}>
        <Card title={"Create Invite"}>
          <Button
            block
            loading={loading}
            type='primary'
            onClick={handleInviteCreation}
            className='invite-form-button'
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            Add new invite
          </Button>
        </Card>
      </Col>
    </Row>
  );
};

const RoomEditionArticles = (props: { articles: IArticle[] }) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [innerArticles, setInnerArticles] = useState<IArticle[]>(
    props.articles
  );
  const [searchResults, setSearchResults] = useState<IArticle[]>([]);
  const params = useParams<{ id: string }>();

  useEffect(() => {
    const searchArticles = async () => {
      if (!searchValue.length) return setSearchResults([]);

      const res = await searchArticlesV2({ search: searchValue });

      if (res.hits.hits) {
        setSearchResults(
          res.hits.hits
            .map((article: { _source: { core: IArticle; id: string } }) => ({
              ...article._source.core,
              id: article._source.id,
            }))
            .filter((article: IArticle) => {
              return !innerArticles.find(
                (innerArticle) => innerArticle._id === article.id
              );
            })
        );
      }
    };

    searchArticles();
  }, [searchValue]);

  const handleAddArticle = async (article: IArticle) => {
    try {
      if (!params.id) throw new Error("No room id provided");

      await postArticleToRoom(params.id, article.id);

      setInnerArticles([article, ...innerArticles]);
      message.success("Article added to the room successfully!");
    } catch (error) {
      message.error("An error occured while adding the article to the room");
      throw error;
    }
  };

  const handleDeleteArticle = async (articleId: string) => {
    const callback = async () => {
      try {
        if (!params.id) throw new Error("No room id provided");

        await deleteArticleFromRoom(params.id, articleId);

        setInnerArticles(
          innerArticles.filter((article) => article._id !== articleId)
        );
        message.success("Article has been successfully removed from the room!");
      } catch (error) {
        message.error(
          "An error occured while removing the article from the room"
        );
        throw error;
      }
    };

    Modal.confirm({
      title: "Are you sure you want to remove this article from the room?",
      content: "This action is irreversible",
      onOk: callback,
      okText: "Yes",
      cancelText: "No",
    });
  };

  const columns: ColumnsType<IArticle> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      filterDropdown: () => (
        <Input.Search
          placeholder='Search articles'
          allowClear
          onSearch={(val) => setSearchFilter(val)}
        />
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#ffc408" : undefined }} />
      ),
      render: (_text: string, article: IArticle) => (
        <Link to={`/content-management/article/${article.slug}`}>
          {article.title}
        </Link>
      ),
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      align: "center",
      width: 81,
      render: (_text: string, article: IArticle) => (
        <Button
          danger
          shape={"circle"}
          icon={<CloseOutlined />}
          onClick={() => handleDeleteArticle(article.id)}
        />
      ),
    },
  ];

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Table
          bordered
          columns={columns}
          dataSource={innerArticles
            .map((article) => ({
              ...article,
              key: article._id,
            }))
            .filter((article) => {
              if (!searchFilter) return true;
              return article.title
                .toLowerCase()
                .includes(searchFilter.toLowerCase());
            })}
        />
      </Col>
      <Col span={8}>
        <Input.Search
          placeholder='Add articles to this room'
          allowClear
          onSearch={(val) => setSearchValue(val)}
        />
        <Divider />
        {searchResults.length ? (
          <Flex
            gap={8}
            flexDirection='column'
            style={{ height: 800, overflowY: "scroll", overflowX: "hidden" }}
          >
            {searchResults.map((article) => (
              <Card key={`${article.slug}-search`}>
                <p>{article.title}</p>
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => handleAddArticle(article)}
                >
                  Add this Article
                </Button>
              </Card>
            ))}
          </Flex>
        ) : null}
      </Col>
    </Row>
  );
};

const RoomEditionContents = (props: { contents: IContent[] }) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [innerContents, setInnerContents] = useState<IContent[]>(
    props.contents
  );
  const [searchResults, setSearchResults] = useState<IContent[]>([]);
  const params = useParams<{ id: string }>();

  useEffect(() => {
    const searchContents = async () => {
      if (!searchValue.length) return setSearchResults([]);

      const res = await searchVideos(searchValue);

      if (res.hits) {
        setSearchResults(
          res.hits
            .map((content: { _source: { core: IContent; id: string } }) => ({
              ...content._source.core,
              id: content._source.id,
            }))
            .filter((content: IContent) => {
              return !innerContents.find(
                (innerContent) => innerContent._id === content.id
              );
            })
        );
      }
    };

    searchContents();
  }, [searchValue]);

  const handleAddContent = async (content: IContent) => {
    try {
      if (!params.id) throw new Error("No room id provided");

      await postContentToRoom(params.id, content.id);

      setInnerContents([content, ...innerContents]);
      message.success("Content added to the room successfully!");
    } catch (error) {
      message.error("An error occured while adding the content to the room");
      throw error;
    }
  };

  const handleDeleteContent = async (contentId: string) => {
    const callback = async () => {
      try {
        if (!params.id) throw new Error("No room id provided");

        await deleteContentFromRoom(params.id, contentId);

        setInnerContents(
          innerContents.filter((content) => content._id !== contentId)
        );
        message.success("Content has been successfully removed from the room!");
      } catch (error) {
        message.error(
          "An error occured while removing the content from the room"
        );
        throw error;
      }
    };

    Modal.confirm({
      title: "Are you sure you want to remove this content from the room?",
      content: "This action is irreversible",
      onOk: callback,
      okText: "Yes",
      cancelText: "No",
    });
  };

  const columns: ColumnsType<IContent> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      filterDropdown: () => (
        <Input.Search
          allowClear
          placeholder='Search contents'
          onSearch={(val) => setSearchFilter(val)}
        />
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#ffc408" : undefined }} />
      ),
      render: (_text: string, content: IContent) => (
        <Link
          to={`/content-management/${
            content.content_format === ContentFormatsEnum.INFOGRAPHIC
              ? "infographic"
              : "video"
          }/${content.slug}`}
        >
          {content.title}
        </Link>
      ),
    },
    {
      title: "Format",
      dataIndex: "content_format",
      key: "content_format",
      width: 150,
      render: (text: IContent["content_format"]) =>
        text === ContentFormatsEnum.INFOGRAPHIC ? "📑 Infographic" : "🎥 Video",
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      align: "center",
      width: 81,
      render: (_text: string, content: IContent) => (
        <Button
          danger
          shape={"circle"}
          icon={<CloseOutlined />}
          onClick={() => handleDeleteContent(content.id)}
        />
      ),
    },
  ];

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Table
          bordered
          columns={columns}
          dataSource={innerContents
            .map((content) => ({
              ...content,
              key: content._id,
            }))
            .filter((content) => {
              if (!searchFilter) return true;
              return content.title
                .toLowerCase()
                .includes(searchFilter.toLowerCase());
            })}
        />
      </Col>
      <Col span={8}>
        <Input.Search
          placeholder='Add videos (infographic coming soon...)'
          allowClear
          onSearch={(val) => setSearchValue(val)}
        />
        <Divider />
        {searchResults.length ? (
          <Flex
            gap={8}
            flexDirection='column'
            style={{ height: 800, overflowY: "scroll", overflowX: "hidden" }}
          >
            {searchResults.map((content) => (
              <Card key={`${content.slug}-search`}>
                <p>{content.title}</p>
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => handleAddContent(content)}
                >
                  Add this Content
                </Button>
              </Card>
            ))}
          </Flex>
        ) : null}
      </Col>
    </Row>
  );
};

const RoomEditionPlaylists = (props: { playlists: IPlaylist[] }) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [innerPlaylists, setInnerPlaylists] = useState<IPlaylist[]>(
    props.playlists
  );
  const [searchResults, setSearchResults] = useState<IPlaylist[]>([]);
  const params = useParams<{ id: string }>();

  useEffect(() => {
    const localSearchPlaylists = async () => {
      if (!searchValue.length) return setSearchResults([]);

      const res = await searchPlaylists(searchValue);

      if (res.hits.hits) {
        setSearchResults(
          res.hits.hits
            .map(
              (playlist: {
                _source: { core: IPlaylist; filters: IPlaylist; id: string };
              }) => ({
                ...playlist._source.core,
                id: playlist._source.id,
                _id: playlist._source.id,
              })
            )
            .filter((playlist: IPlaylist) => {
              return !innerPlaylists.find(
                (innerPlaylist) => innerPlaylist._id === playlist._id
              );
            })
        );
      }
    };

    localSearchPlaylists();
  }, [searchValue]);

  const handleAddPlaylist = async (playlist: IPlaylist) => {
    try {
      if (!params.id) throw new Error("No room id provided");

      await postPlaylistToRoom(params.id, playlist.id);

      setInnerPlaylists([playlist, ...innerPlaylists]);
      message.success("Playlist added to the room successfully!");
    } catch (error) {
      message.error("An error occured while adding the playlist to the room");
      throw error;
    }
  };

  const handleDeletePlaylist = async (playlistId: string) => {
    const callback = async () => {
      try {
        if (!params.id) throw new Error("No room id provided");

        await deletePlaylistFromRoom(params.id, playlistId);

        setInnerPlaylists(
          innerPlaylists.filter((playlist) => playlist._id !== playlistId)
        );
        message.success(
          "Playlist has been successfully removed from the room!"
        );
      } catch (error) {
        message.error(
          "An error occured while removing the playlist from the room"
        );
        throw error;
      }
    };

    Modal.confirm({
      title: "Are you sure you want to remove this playlist from the room?",
      content: "This action is irreversible",
      onOk: callback,
      okText: "Yes",
      cancelText: "No",
    });
  };

  const columns: ColumnsType<IPlaylist> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      filterDropdown: () => (
        <Input.Search
          allowClear
          placeholder='Search Playlists'
          onSearch={(val) => setSearchFilter(val)}
        />
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#ffc408" : undefined }} />
      ),
      render: (_text: string, playlist: IPlaylist) => (
        <Link to={`/content-management/playlist/${playlist._id}`}>
          {playlist.title}
        </Link>
      ),
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      align: "center",
      width: 81,
      render: (_text: string, playlist: IPlaylist) => (
        <Button
          danger
          shape={"circle"}
          icon={<CloseOutlined />}
          onClick={() => handleDeletePlaylist(playlist._id)}
        />
      ),
    },
  ];

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Table
          bordered
          columns={columns}
          dataSource={innerPlaylists
            .map((playlist) => ({
              ...playlist,
              key: playlist._id,
            }))
            .filter((playlist) => {
              if (!searchFilter) return true;
              return playlist.title
                .toLowerCase()
                .includes(searchFilter.toLowerCase());
            })}
        />
      </Col>
      <Col span={8}>
        <Input.Search
          placeholder='Add playlists'
          allowClear
          onSearch={(val) => setSearchValue(val)}
        />
        <Divider />
        {searchResults.length ? (
          <Flex
            gap={8}
            flexDirection='column'
            style={{ height: 800, overflowY: "scroll", overflowX: "hidden" }}
          >
            {searchResults.map((playlist) => (
              <Card key={`${playlist._id}-search`}>
                <p>{playlist.title}</p>
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => handleAddPlaylist(playlist)}
                >
                  Add this playlist
                </Button>
              </Card>
            ))}
          </Flex>
        ) : null}
      </Col>
    </Row>
  );
};

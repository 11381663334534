import { useContext, useState } from "react";
import { Button, Card, Form, Input, Select, Spin, notification } from "antd";
import { GlobalContext } from "~/context/global.context";
import { Spacer } from "./shared/global";
import { sendNotification } from "~/services";
import { SupportedLanguage } from "~/model";
import { languageOptions } from "~/utils/helpers";

const NotificationEdition = () => {
  const { organisationList } = useContext(GlobalContext);
  const [form] = Form.useForm();
  const [notificationSending, setNotificationSending] =
    useState<boolean>(false);

  const handleFinish = async (notificationData: {
    title: string;
    text: string;
    url: string;
    language: SupportedLanguage;
    organisation: string;
  }) => {
    setNotificationSending(true);

    await sendNotification(notificationData);

    form.resetFields();
    setNotificationSending(false);

    notification.success({
      message: "Notification sent",
      description: "The notification was sent successfully",
    });
  };

  return (
    <div className='basic-layout'>
      <h1 style={{ fontWeight: 800, fontSize: "30px" }}>Push Notification</h1>
      <Spacer />
      <Card>
        <Spin spinning={notificationSending}>
          <Form
            form={form}
            layout='vertical'
            name='send_notification'
            onFinish={handleFinish}
          >
            <Form.Item key='title' label='Title' name='title' required>
              <Input placeholder='Title' />
            </Form.Item>
            <Form.Item key='text' label='Text' name='text' required>
              <Input placeholder='Notification content' />
            </Form.Item>
            <Form.Item key='url' label='Link' name='url' required>
              <Input placeholder='URL' />
            </Form.Item>
            <Form.Item key='language' label='Language' name='language' required>
              <Select placeholder='Language' options={languageOptions} />
            </Form.Item>
            <Form.Item
              key='organisation'
              label='Organisation'
              name='organisation'
            >
              <Select>
                {organisationList.map((organisation) => (
                  <Select.Option
                    key={organisation._id}
                    value={organisation._id}
                  >
                    {organisation.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                className='send-notification-form-button'
                size='large'
                block
              >
                Send notification
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    </div>
  );
};

export default NotificationEdition;

import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Spin,
} from "antd";
import { getVideoRequest, validateVideoRequest } from "../services";
import { useParams } from "react-router-dom";
import { IVideoRequest, SupportedLanguage } from "../model";
import ApiVideoPlayer from "@api.video/react-player";
import { store } from "../store/store";
import { SaveOutlined } from "@ant-design/icons";

const VideoRequestEdition = () => {
  const params = useParams();
  const { tagList } = store.getState();
  const [form] = Form.useForm();
  const [requestData, setRequestData] = useState<IVideoRequest | null>(null);

  useEffect(() => {
    getRequestData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRequestData = async () => {
    if (!params.id) return;
    const request = await getVideoRequest(params.id);
    setRequestData(request);
  };

  const handleSubmit = async () => {
    if (!params.id) return;
    const values = form.getFieldsValue();
    console.log("values", values);

    const res = await validateVideoRequest(params.id);

    console.log("res", res);
  };

  const videoId = requestData?.videos?.[0]?.videoId;

  if (!requestData) return <Spin />;

  return (
    <Layout
      style={{
        overflow: "hidden",
        boxSizing: "border-box",
        height: "100vh",
      }}
    >
      <Layout.Content
        style={{
          padding: "20px 50px 50px",
          boxSizing: "border-box",
        }}
      >
        <Row
          justify='space-between'
          align='middle'
          style={{ margin: "20px 0" }}
        >
          <h1 style={{ fontWeight: 800, fontSize: "30px" }}>Video Requests</h1>

          <Button
            icon={<SaveOutlined />}
            type='primary'
            htmlType='submit'
            onClick={() => form.submit()}
          >
            Save Changes
          </Button>
        </Row>
        <Row gutter={16}>
          <Col span={16}>
            <Card>
              <Form
                form={form}
                onFinish={() => handleSubmit()}
                initialValues={{
                  title: requestData?.videos?.[0].title,
                  language: requestData?.videos?.[0].language,
                }}
              >
                <Form.Item name='title' label='Title'>
                  <Input placeholder='Title' type='text' />
                </Form.Item>

                <Form.Item name='language' label='Language'>
                  <Select placeholder='Video language' style={{ width: 200 }}>
                    <Select.Option value={SupportedLanguage.EN}>
                      English
                    </Select.Option>
                    <Select.Option value={SupportedLanguage.FR}>
                      French
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item name='videoFormat' label='Video Format'>
                  <Select placeholder='Video Format' style={{ width: 200 }}>
                    <Select.Option value='story'>Story</Select.Option>
                    <Select.Option value='article'>Article</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name='medical_specialties'
                  label='Medical Specialties'
                >
                  <Select
                    placeholder='Search for a specialty'
                    mode='multiple'
                    allowClear
                    options={tagList
                      .filter((el) => !el.parent)
                      .map((specialty) => ({
                        label: specialty.translations["en"],
                        value: specialty._id,
                      }))}
                  />
                </Form.Item>

                <Form.Item name='tags' label='Tags'>
                  <Select
                    placeholder='Search for a subspecialty (tag)'
                    mode='multiple'
                    allowClear
                    options={tagList
                      .filter((el) => !!el.parent)
                      .map((specialty) => ({
                        label: `${specialty.translations["en"]} (${specialty.parent?.translations["en"]})`,
                        value: specialty._id,
                      }))}
                  />
                </Form.Item>

                <Form.Item name='keywords' label='Keywords'>
                  <Input placeholder='Enter a keyword' />
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              {videoId ? (
                <ApiVideoPlayer
                  video={{ id: videoId }}
                  style={{
                    width: "100%",
                    height: 600,
                  }}
                />
              ) : null}
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default VideoRequestEdition;

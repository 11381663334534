/* eslint-disable @typescript-eslint/no-explicit-any */
import queryString from "query-string";

import {
  ILoginResponse,
  IArticle,
  IJournal,
  IUser,
  IHistory,
  IOrganisation,
  ITag,
  ICrawling,
  IParsing,
  ICompany,
  IPlaylist,
  IProfession,
  IInfographic,
  IVideo,
  IInvite,
  ICongress,
  IImage,
  ILemonRequest,
  IQuery,
  INectar,
  INectarQuestion,
  SupportedLanguage,
  IQueryParsing,
  PermissionLabel,
  PermissionEnum,
  IUserAdmin,
  IContent,
  IPaginedResult,
  FeatureEnum,
  VideoRequestStatus,
  IRoom,
  TranslateArticleErrorType,
  QueryContentParamsModel,
} from "../model";
import {
  IChangePasswordDto,
  IResetPasswordDto,
  ISignUpDto,
} from "../model/dto/auth-dto";
import { ICreateArticleDto, IUpdateArticleDto } from "../model/dto/article-dto";

import { AxiosPromise } from "../utils/axios";
import { ICreateJournalDto, IUpdateJournalDto } from "../model/dto/journal-dto";
import { ICreateTagDto, IUpdateTagDto } from "../model/dto/tag-dto";
import {
  ICreateCrawlingDto,
  IUpdateCrawlingDto,
} from "../model/dto/crawling-dto";
import {
  ICreateCompanyDto,
  IUpdateCompanyDto,
  ICreateCompanyInfoDto,
} from "../model/dto/company-dto";
import {
  ICreatePlaylistDto,
  IUpdatePlaylistDto,
} from "../model/dto/playlist-dto";
import { ICreateNotificationDto } from "../model/dto/notification-dto";
import { ICreateVideoDto, IUpdateVideoDto } from "../model/dto/video-dto";
import {
  ICreateOrganisationDto,
  IUpdateOrganisationDto,
} from "../model/dto/organisation-dto";
import { IUpdateUserDto } from "../model/dto/user-dto";
import {
  ICreateCongressDto,
  IUpdateCongressDto,
} from "../model/dto/congress-dto";
import { IUpdateImageDto } from "../model/dto/image-dto";
import { notification } from "antd";
import axios from "axios";
import {
  ICreateProfessionDto,
  IUpdateProfessionDto,
} from "~/model/dto/profession-dto";
import { IConfigureRoomDto, ICreateRoomDto } from "~/model/dto/room-dto";

const url = import.meta.env.VITE_APP_API_URL;
const parsingAPI = import.meta.env.VITE_APP_PARSING_API;
const crawlingAPI = import.meta.env.VITE_APP_CRAWLING_API;

export enum ContentSortBy {
  CREATION_DATE = "creationDate",
  PUBLICATION_DATE = "publicationDate",
}

export const toDbArticle = (summarizedArticle: any) => {
  return {
    title: summarizedArticle.title,
    objectives: summarizedArticle.objectives,
    background: summarizedArticle.background.join(" "),
    methodology: summarizedArticle.methodology.join(" "),
    limitations: summarizedArticle.limitations.join(" "),
    results: summarizedArticle.results,
    conclusion: summarizedArticle.conclusion,
    doi: summarizedArticle.source_id,
    primary_author: summarizedArticle.primary_author,
    authors: summarizedArticle.authors,
  };
};

// Authentication

export const register = async (data: ISignUpDto): Promise<ILoginResponse> => {
  const user = await AxiosPromise("post", `${url}/api/v1/auth/register`, data);
  return user.data;
};

export const authenticate = async (
  email: string,
  password: string
): Promise<ILoginResponse> => {
  const data = {
    email,
    password,
  };
  const user = await AxiosPromise(
    "post",
    `${url}/api/v1/auth/adminauthenticate`,
    data
  );
  if (user) {
    localStorage.setItem(
      "juisci-accessToken",
      user.data.user.stsTokenManager.accessToken
    );
    localStorage.setItem(
      "juisci-refreshToken",
      user.data.user.stsTokenManager.refreshToken
    );
    localStorage.setItem(
      "juisci-expirationTime",
      user.data.user.stsTokenManager.expirationTime
    );
  }
  return user.data;
};

export const resetPasswordToken = async (email: string) => {
  const data = {
    email,
  };
  const request = await AxiosPromise(
    "post",
    `${url}/api/v1/auth/resetpasswordtoken`,
    data
  );
  return request.data;
};

export const resetPassword = async (data: IResetPasswordDto) => {
  const request = await AxiosPromise(
    "post",
    `${url}/api/v1/auth/resetpassword`,
    data
  );
  return request.data;
};

export const changePassword = async (data: IChangePasswordDto) => {
  const request = await AxiosPromise(
    "post",
    `${url}/api/v1/auth/changepassword`,
    data
  );
  return request.data;
};

// Users

export const getMe = async (): Promise<IUserAdmin> => {
  const user = await AxiosPromise("get", `${url}/api/v1/user/me`);
  return user.data;
};

export const getUser = async (uid: string): Promise<IUser> => {
  const user = await AxiosPromise("get", `${url}/api/v1/user/${uid}`);
  return user.data;
};

export const updateUserRoles = async (
  userId: string,
  roles: string[]
): Promise<IUser> => {
  const user = await AxiosPromise(
    "patch",
    `${url}/api/v1/admin/user/${userId}/roles`,
    {
      roles,
    }
  );
  return user.data;
};

export const getLemonCertifiedRequests = async (params?: {
  limit?: number;
  offset?: number;
}): Promise<Array<ILemonRequest>> => {
  const { data: users } = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/user/lemon-certified-request`,
    {},
    {
      ...params,
    }
  );

  return users;
};

export const acceptLemonCertifiedRequest = async (requestId: string) => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/user/lemon-certified-request/${requestId}/accept`
  );
  return data;
};

export const rejectLemonCertifiedRequest = async (requestId: string) => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/user/lemon-certified-request/${requestId}/reject`
  );
  return data;
};

export const addUserLemonCertification = async (userId: string) => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/user/${userId}/lemon-certified`
  );
  return data;
};

export const removeUserLemonCertification = async (userId: string) => {
  const { data } = await AxiosPromise(
    "delete",
    `${url}/api/v1/admin/user/${userId}/lemon-certified`
  );
  return data;
};

export const getCertifiedUsers = async (params?: {
  limit?: number;
  offset?: number;
}) => {
  const { data: users } = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/user/lemon-certified`,
    {},
    {
      ...params,
    }
  );

  return users;
};

// Images

export const updateImage = async (
  imageId: string,
  data: IUpdateImageDto
): Promise<IImage> => {
  const image = await AxiosPromise(
    "put",
    `${url}/api/v1/admin/image/${imageId}`,
    data
  );
  return image.data;
};

export const updateImageOrder = async (
  imageId: string,
  order: number
): Promise<IImage> => {
  const image = await AxiosPromise(
    "patch",
    `${url}/api/v1/admin/image/${imageId}/order`,
    { order }
  );
  return image.data;
};

// Articles

export const listArticles = async (
  query?: IQuery
): Promise<{ docs: IArticle[]; meta: { total: number } }> => {
  const articleList = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/article`,
    {},
    {
      ...query,
      offset: query?.offset ? query.offset : 0,
    }
  );
  return articleList.data;
};

export const listAnalytics = async (query?: {
  [key: string]: any;
}): Promise<IArticle[]> => {
  if (query && Object.keys(query).length)
    Object.keys(query).forEach((key: string) => {
      if (!query[key]) delete query[key];
    });
  const articleList = await AxiosPromise(
    "get",
    `${url}/api/v1/article/analytics?${
      query ? queryString.stringify(query) : ""
    }`
  );
  return articleList.data;
};

export const searchArticles = async (
  searchValue: string,
  query?: { [key: string]: any }
): Promise<{ docs: IArticle[] }> => {
  if (query && Object.keys(query).length)
    Object.keys(query).forEach((key: string) => {
      if (!query[key] && key !== "offset") delete query[key];
    });
  const articleList = await AxiosPromise(
    "get",
    `${url}/api/v1/article/search?search=${searchValue}&${
      query ? queryString.stringify(query) : ""
    }`
  );
  return articleList.data[0];
};

export const searchArticlesV2 = async (
  query?: QueryContentParamsModel & { medical_specialties?: string[] }
): Promise<{
  hits: {
    hits: { _source: { core: IArticle; filters: IArticle; id: string } }[];
  };
}> => {
  const articleList = await AxiosPromise(
    "get",
    `${url}/api/v1/article/search-v2`,
    {},
    {
      ...query,
      adminPanel: true,
    }
  );
  return articleList.data;
};

export const getArticle = async (uid: string): Promise<IArticle> => {
  const article = await AxiosPromise("get", `${url}/api/v1/article/${uid}`);
  return article.data;
};

export const createArticle = async (
  data: ICreateArticleDto
): Promise<IArticle> => {
  const article = await AxiosPromise("post", `${url}/api/v1/article`, data);
  return article.data;
};

export const updateArticle = async (
  uid: string,
  data: IUpdateArticleDto
): Promise<IArticle> => {
  const article = await AxiosPromise(
    "patch",
    `${url}/api/v1/article/${uid}`,
    data
  );
  return article.data;
};

export const deleteArticle = async (
  uid: string
): Promise<{
  ok?: number;
  n?: number;
}> => {
  const article = await AxiosPromise("delete", `${url}/api/v1/article/${uid}`);
  return article.data;
};

export const publishArticle = async (articleId: string): Promise<IArticle> => {
  const article = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/article/${articleId}/publish`
  );
  return article.data;
};

export const unpublishArticle = async (uid: string): Promise<IArticle> => {
  const article = await AxiosPromise(
    "post",
    `${url}/api/v1/article/${uid}/unpublish`
  );
  return article.data;
};

export const getArticleCsv = async (articleIds: string[]): Promise<string> => {
  const articleCsv = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/article/download`,
    articleIds.length > 0
      ? {
          articleIds,
        }
      : undefined
  );
  return articleCsv.data;
};

export const getAnalyticsCsv = async (): Promise<string> => {
  const analyticsCsv = await AxiosPromise(
    "get",
    `${url}/api/v1/article/analytics/download`
  );
  return analyticsCsv.data;
};

export const uploadArticleImage = async (
  articleSlug: string,
  file: File
): Promise<IArticle> => {
  const contentType = "multipart/form-data";
  const formData = new FormData();
  formData.append("file", file);
  const article = await AxiosPromise(
    "post",
    `${url}/api/v1/article/${articleSlug}/image`,
    formData,
    {},
    contentType
  );
  return article.data;
};

export const updateArticleImage = async (
  imageId: string,
  payload: { legend: string }
) => {
  const image = await AxiosPromise(
    "patch",
    `${url}/api/v1/image/${imageId}`,
    payload
  );
  return image.data;
};

export const deleteArticleImage = async (
  articleSlug: string,
  imagePath: string
): Promise<void> => {
  const { data } = await AxiosPromise(
    "delete",
    `${url}/api/v1/article/${articleSlug}/image`,
    {
      path: imagePath,
    }
  );

  return data;
};

export const deleteArticleImages = async (
  articleSlug: string
): Promise<void> => {
  const { data } = await AxiosPromise(
    "delete",
    `${url}/api/v1/article/${articleSlug}/images`
  );
  return data;
};

export const postArticleAudioSpeech = async (
  articleId: string,
  file: File
): Promise<IArticle> => {
  // POST api/v1/admin/article/:id/speech
  // key attendu dans le formData contenant le fichier: `speech`

  const contentType = "multipart/form-data";
  const formData = new FormData();
  formData.append("speech", file);

  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/article/${articleId}/speech`,
    formData,
    {},
    contentType
  );

  return data;
};

// Infographics

export const listInfographics = async (query?: {
  [key: string]: any;
}): Promise<{ docs: IInfographic[]; meta: { total: number } }> => {
  const content = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/content/infographic`,
    {},
    {
      ...query,
      offset: query?.offset ? query.offset : 0,
    }
  );
  return content.data;
};

export const getInfographic = async (
  infographicId: string
): Promise<IInfographic> => {
  const content = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/content/infographic/${infographicId}`
  );
  return content.data;
};

export const createInfographic = async (
  data: IInfographic
): Promise<IInfographic> => {
  const content = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/content/infographic`,
    {
      ...data,
    }
  );
  return content.data;
};

export const updateInfographic = async (
  infographicId: string,
  data: IInfographic
): Promise<IInfographic> => {
  const content = await AxiosPromise(
    "put",
    `${url}/api/v1/admin/content/infographic/${infographicId}`,
    {
      ...data,
    }
  );
  return content.data;
};

export const deleteInfographic = async (
  infographicId: string
): Promise<{
  ok?: number;
  n?: number;
}> => {
  const content = await AxiosPromise(
    "delete",
    `${url}/api/v1/admin/content/${infographicId}`
  );
  return content.data;
};

export const publishInfographic = async (
  infographicId: string
): Promise<IInfographic> => {
  const content = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/content/${infographicId}/publish`
  );
  return content.data;
};

export const unpublishInfographic = async (
  infographicId: string
): Promise<IInfographic> => {
  const content = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/content/${infographicId}/unpublish`
  );
  return content.data;
};

export const addInfographicArticle = async (
  infographicId: string,
  articleId: string
): Promise<IInfographic | null> => {
  try {
    const content = await AxiosPromise(
      "post",
      `${url}/api/v1/admin/content/${infographicId}/article`,
      { articleId }
    );
    return content.data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error("Couldn't add article.", error.message);
    }
    return null;
  }
};

export const removeInfographicArticle = async (
  infographicId: string,
  articleId: string
): Promise<IInfographic | null> => {
  try {
    const content = await AxiosPromise(
      "delete",
      `${url}/api/v1/admin/content/${infographicId}/article`,
      { articleId }
    );
    return content.data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error("Couldn't remove article.", error.message);
    }
    return null;
  }
};

export const uploadInfographicImage = async (
  infographicId: string,
  file: File
): Promise<IInfographic> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const contentType = "multipart/form-data";
    const formData = new FormData();
    formData.append("image", file);
    const content = await AxiosPromise(
      "post",
      `${url}/api/v1/admin/content/infographic/${infographicId}/image`,
      formData,
      {},
      contentType
    );
    return content.data;
  } catch (error) {
    throw error;
  }
};

export const uploadInfographiThumbnail = async (
  infographicId: string,
  file: File
): Promise<IInfographic> => {
  const contentType = "multipart/form-data";
  const formData = new FormData();
  formData.append("image", file);
  const content = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/content/infographic/${infographicId}/thumbnail`,
    formData,
    {},
    contentType
  );
  return content.data;
};

export const associateInfographicToArticle = async (params: {
  articleId: string;
  infographicId: string;
}) => {
  const content = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/article/${params.articleId}/associate-infographic`,
    { infographic: params.infographicId }
  );
  return content.data;
};

export const dissociateInfographicFromArticle = async (params: {
  articleId: string;
  infographicId: string;
}) => {
  const content = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/article/${params.articleId}/dissociate-infographic`,
    { infographic: params.infographicId }
  );
  return content.data;
};

// Videos

export const listVideos = async (
  query?: IQuery
): Promise<{ docs: IVideo[]; meta: { total: number } }> => {
  const content = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/content/video`,
    {},
    { ...query }
  );
  return content.data;
};

export const getVideo = async (uid: string): Promise<IVideo> => {
  const content = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/content/slug/${uid}`
  );
  return content.data;
};

export const createVideo = async (
  data: ICreateVideoDto
): Promise<IVideo | null> => {
  try {
    const content = await AxiosPromise(
      "post",
      `${url}/api/v1/admin/content/video`,
      {
        ...data,
        content_format: "Video",
      }
    );
    return content.data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error("Couldn't create video", error.message);
    }
    return null;
  }
};

export const updateVideo = async (
  videoId: string,
  data: IUpdateVideoDto
): Promise<IVideo> => {
  const content = await AxiosPromise(
    "put",
    `${url}/api/v1/admin/content/video/${videoId}`,
    {
      ...data,
      content_format: "Video",
    }
  );
  return content.data;
};

export const deleteVideo = async (
  videoId: string
): Promise<{
  ok?: number;
  n?: number;
}> => {
  const content = await AxiosPromise(
    "delete",
    `${url}/api/v1/admin/content/${videoId}`
  );
  return content.data;
};

export const publishVideo = async (videoId: string): Promise<IVideo> => {
  const content = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/content/${videoId}/publish`
  );
  return content.data;
};

export const unpublishVideo = async (videoId: string): Promise<IVideo> => {
  const content = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/content/${videoId}/unpublish`
  );
  return content.data;
};

export const addArticleToContent = async (
  contentId: string,
  articleId: string
): Promise<IVideo> => {
  const content = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/content/${contentId}/article`,
    {
      articleId,
    }
  );
  return content.data;
};

export const deleteArticleFromContent = async (
  contentId: string,
  articleId: string
): Promise<IVideo> => {
  const content = await AxiosPromise(
    "delete",
    `${url}/api/v1/admin/content/${contentId}/article`,
    {
      articleId,
    }
  );
  return content.data;
};

export const addVideoArticle = async (
  uid: string,
  slug: string
): Promise<IVideo> => {
  const content = await AxiosPromise(
    "post",
    `${url}/api/v1/content/${uid}/article`,
    {
      slug,
    }
  );
  return content.data;
};

export const uploaAPIVideo = async (
  videoId: string,
  file: File
): Promise<IVideo | null> => {
  try {
    const contentType = "multipart/form-data";
    const formData = new FormData();
    formData.append("video", file);
    const content = await AxiosPromise(
      "post",
      `${url}/api/v1/admin/content/video/${videoId}`,
      formData,
      {},
      contentType
    );
    return content.data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error("Couldn't upload video", error.message);
    }
    return null;
  }
};

export const getVideoRequestList = async (status?: VideoRequestStatus[]) => {
  const { data } = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/video-request`,
    undefined,
    {
      status: status
        ? status.toString()
        : [
            VideoRequestStatus.WAITING,
            VideoRequestStatus.WAITING_VALIDATION,
          ].toString(),
    }
  );
  return data;
};

export const getVideoRequest = async (requestId: string) => {
  const { data } = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/video-request/${requestId}`
  );
  return data;
};

export const postVideoRequest = async (
  requestId: string,
  file: File,
  params: Record<string, string>
) => {
  const contentType = "multipart/form-data";
  const formData = new FormData();
  formData.append("video", file);
  formData.append("title", params.title);
  formData.append("language", params.language);

  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/video-request/${requestId}/video`,
    formData,
    {},
    contentType
  );

  return data;
};

export const validateVideoRequest = async (requestId: string) => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/video-request/${requestId}/send-to-validation`,
    {}
  );

  return data;
};

export const rejectVideoRequest = async (
  requestId: string,
  reason?: string
) => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/video-request/${requestId}/reject`,
    { reason }
  );

  return data;
};

export const feedbackVideoRequest = async ({
  requestId,
  message,
  timer,
  language,
}: {
  requestId: string;
  message: string;
  timer: string;
  language: SupportedLanguage;
}) => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/video-request/${requestId}/feedback`,
    { message, timer, language }
  );
  return data;
};

export const deleteVideoRequest = async ({
  requestId,
  videoId,
}: {
  requestId: string;
  videoId: string;
}) => {
  const { data } = await AxiosPromise(
    "delete",
    `${url}/api/v1/admin/video-request/${requestId}/video`,
    { videoId }
  );
  return data;
};

// Journals

export const listJournals = async (): Promise<IJournal[]> => {
  const journalList = await AxiosPromise(
    "get",
    `${url}/api/v1/journal/?limit=10000`
  );
  return journalList.data;
};

export const getJournal = async (journalId: string): Promise<IJournal> => {
  const journal = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/journal/${journalId}`
  );
  return journal.data;
};

export const createJournal = async (
  data: ICreateJournalDto
): Promise<IJournal> => {
  try {
    const journal = await AxiosPromise("post", `${url}/api/v1/journal`, data);
    return journal.data;
  } catch (error) {
    if (
      axios.isAxiosError(error) &&
      error?.response?.data.message.includes("duplicate")
    )
      notification.error({
        message: "Journal with this name already exists",
      });
    throw error;
  }
};

export const updateJournal = async (
  journalId: string,
  data: IUpdateJournalDto
): Promise<IJournal> => {
  delete data["image"];
  const journal = await AxiosPromise(
    "put",
    `${url}/api/v1/admin/journal/${journalId}`,
    data
  );
  return journal.data;
};

export const deleteJournal = async (
  journalId: string
): Promise<{
  ok?: number;
  n?: number;
}> => {
  const journal = await AxiosPromise(
    "delete",
    `${url}/api/v1/admin/journal/${journalId}`
  );
  return journal.data;
};

export const uploadJournalImage = async (
  journalId: string,
  file: File
): Promise<IJournal> => {
  const contentType = "multipart/form-data";
  const formData = new FormData();
  formData.append("image", file);
  const journal = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/journal/image/${journalId}`,
    formData,
    {},
    contentType
  );
  return journal.data;
};

// Companies

export const listCompanies = async (query?: {
  [key: string]: string;
}): Promise<ICompany[]> => {
  if (query && Object.keys(query).length)
    Object.keys(query).forEach((key: string) => {
      if (!query[key]) delete query[key];
    });
  const companyList = await AxiosPromise(
    "get",
    `${url}/api/v1/company/?${query ? queryString.stringify(query) : ""}`
  );
  return companyList.data;
};

export const getCompany = async (companyId: string): Promise<ICompany> => {
  const company = await AxiosPromise(
    "get",
    `${url}/api/v1/company/${companyId}`
  );
  return company.data;
};

export const createCompany = async (
  data: ICreateCompanyDto
): Promise<ICompany> => {
  const company = await AxiosPromise("post", `${url}/api/v1/company`, data);
  return company.data;
};

export const updateCompany = async (
  companyId: string,
  data: IUpdateCompanyDto
): Promise<ICompany> => {
  const company = await AxiosPromise(
    "patch",
    `${url}/api/v1/company/${companyId}`,
    data
  );
  return company.data;
};

export const deleteCompany = async (
  companyId: string
): Promise<{
  ok?: number;
  n?: number;
}> => {
  const company = await AxiosPromise(
    "delete",
    `${url}/api/v1/company/${companyId}`
  );
  return company.data;
};

export const publishCompany = async (companyId: string): Promise<ICompany> => {
  const company = await AxiosPromise(
    "post",
    `${url}/api/v1/company/${companyId}/publish`
  );
  return company.data;
};

export const unpublishCompany = async (
  companyId: string
): Promise<ICompany> => {
  const company = await AxiosPromise(
    "post",
    `${url}/api/v1/company/${companyId}/unpublish`
  );
  return company.data;
};

export const addCompanyInfo = async (
  companyId: string,
  info: ICreateCompanyInfoDto
): Promise<ICompany> => {
  const company = await AxiosPromise(
    "post",
    `${url}/api/v1/company/${companyId}/infos`,
    {
      infos: [info],
    }
  );
  return company.data;
};

export const removeCompanyInfo = async (
  companyId: string,
  infoId: string
): Promise<ICompany> => {
  const company = await AxiosPromise(
    "delete",
    `${url}/api/v1/company/${companyId}/infos`,
    {
      info: infoId,
    }
  );
  return company.data;
};

export const uploadCompanyImage = async (
  uid: string,
  file: File
): Promise<ICompany> => {
  const contentType = "multipart/form-data";
  const formData = new FormData();
  formData.append("file", file);
  const company = await AxiosPromise(
    "post",
    `${url}/api/v1/company/${uid}/image`,
    formData,
    {},
    contentType
  );
  return company.data;
};

// Playlists

export const listSponsoredPlaylists = async (
  query?: IQuery & { kol?: boolean }
): Promise<{ docs: IPlaylist[]; meta: { total: number } }> => {
  const playlistList = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/playlist`,
    undefined,
    {
      ...query,
    }
  );
  return playlistList.data;
};

export const searchPlaylists = async (
  searchValue: string,
  query?: { [key: string]: any }
): Promise<{
  hits: {
    hits: { _source: { core: IPlaylist; filters: IPlaylist; id: string } }[];
  };
}> => {
  const playlistList = await AxiosPromise(
    "get",
    `${url}/api/v1/playlist/search-v2/`,
    undefined,
    {
      search: searchValue,
      adminPanel: true,
      ...query,
    }
  );
  return playlistList.data;
};

export const searchVideos = async (
  searchValue: string,
  query?: Record<string, unknown>
): Promise<{
  hits: {
    _source: { core: IVideo; filters: IVideo; view: IVideo; id: string };
  }[];
  total: number;
}> => {
  const results = await AxiosPromise(
    "get",
    `${url}/api/v1/content/search-v2`,
    null,
    {
      search: searchValue,
      adminPanel: true,
      content_format: "video",
      ...query,
    }
  );
  return results.data.hits;
};

export const getPlaylist = async (playlistId: string): Promise<IPlaylist> => {
  const playlist = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/playlist/${playlistId}`
  );
  return playlist.data;
};

export const createPlaylist = async (
  data: ICreatePlaylistDto
): Promise<IPlaylist> => {
  const playlist = await AxiosPromise("post", `${url}/api/v1/admin/playlist`, {
    ...data,
    sponsored: true,
  });
  return playlist.data;
};

export const updatePlaylist = async (
  playlistId: string,
  data: IUpdatePlaylistDto
): Promise<IPlaylist> => {
  const playlist = await AxiosPromise(
    "patch",
    `${url}/api/v1/admin/playlist/${playlistId}`,
    data
  );
  return playlist.data;
};

export const deletePlaylist = async (
  playlistId: string
): Promise<{
  ok?: number;
  n?: number;
}> => {
  const playlist = await AxiosPromise(
    "delete",
    `${url}/api/v1/admin/playlist/${playlistId}`
  );
  return playlist.data;
};

export const publishPlaylist = async (
  playlistId: string
): Promise<IPlaylist> => {
  const playlist = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/playlist/${playlistId}/publish`
  );
  return playlist.data;
};

export const unpublishPlaylist = async (
  playlistId: string
): Promise<IPlaylist> => {
  const playlist = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/playlist/${playlistId}/unpublish`
  );
  return playlist.data;
};

export const addPlaylistArticle = async (
  playlistId: string,
  slug: string
): Promise<IPlaylist> => {
  const playlist = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/playlist/${playlistId}/article`,
    {
      slug,
    }
  );
  return playlist.data;
};

export const removePlaylistArticle = async (
  playlistId: string,
  slug: string
): Promise<IPlaylist> => {
  const playlist = await AxiosPromise(
    "delete",
    `${url}/api/v1/admin/playlist/${playlistId}/article`,
    {
      slug,
    }
  );
  return playlist.data;
};

export const uploadPlaylistImage = async (
  playlistId: string,
  file: File
): Promise<IPlaylist> => {
  const contentType = "multipart/form-data";
  const formData = new FormData();
  formData.append("file", file);
  const playlist = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/playlist/${playlistId}/image`,
    formData,
    {},
    contentType
  );
  return playlist.data;
};

export const getPlaylistsKOL = async (params?: {
  limit?: number;
  offset?: number;
}) => {
  const { data } = await AxiosPromise("get", `${url}/api/v1/playlist/kol`, {
    params,
  });
  return data;
};

// Users

export const listUsers = async (
  query?: IQuery
): Promise<{
  docs: IUser[];
  meta: {
    total: number;
    offset: number;
    limit: number;
  };
}> => {
  if (query?.language === undefined) delete query?.language;

  const userList = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/user`,
    {},
    {
      limit: 100,
      offset: 0,
      ...query,
    }
  );
  return userList.data;
};

export const updateUser = async (userId: string, data: IUpdateUserDto) => {
  const user = await AxiosPromise(
    "patch",
    `${url}/api/v1/user/${userId}`,
    data
  );
  return user.data;
};

export const searchUsers = async (
  search: string,
  query?: { [key: string]: any }
): Promise<IUser[]> => {
  const { data } = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/user/search`,
    undefined,
    {
      ...query,
      search,
    }
  );
  return data;
};

export const getUsersCount = async (query?: {
  [key: string]: any;
}): Promise<{ count: number }> => {
  const userCount = await AxiosPromise(
    "get",
    `${url}/api/v1/user/count?${query ? queryString.stringify(query) : ""}`
  );
  return userCount.data;
};

export const getUserCsv = async (query?: {
  mainSpecialty?: string;
  country?: string;
  device?: string;
  origin?: string;
  profession?: string;
  organisation?: string;
  role?: string;
}): Promise<string> => {
  const userCsv = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/user/export/csv`,
    {},
    {
      ...query,
    }
  );
  return userCsv.data;
};

export const deleteUser = async (userUid: string): Promise<void> => {
  await AxiosPromise("delete", `${url}/api/v1/user/${userUid}`);
};

// History

export const listHistory = async (): Promise<IHistory[]> => {
  const historyList = await AxiosPromise("get", `${url}/api/v1/history`);

  return historyList.data;
};

// Formatting

export const formatArray = async (array: string[]): Promise<string[]> => {
  const formattedArray = await AxiosPromise(
    "post",
    `${url}/api/v1/formatting/array`,
    { array }
  );
  return formattedArray.data;
};

// Parsing

export const listParsings = async (
  query?: IQueryParsing
): Promise<{
  docs: IParsing[];
  meta: { limit: number; offset: number; total: number };
}> => {
  const parsingList = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/parsing`,
    {},
    {
      ...query,
    }
  );
  return parsingList.data;
};

export const createBatchedParsing = async (
  files: File[],
  batchName: string
) => {
  const formData = new FormData();
  formData.append("batchName", batchName);

  files.forEach((file) => {
    formData.append("files", file);
  });

  const parsing = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/parsing/batch`,
    formData,
    {},
    "multipart/form-data"
  );
  return parsing.data;
};

export const getParsing = async (parsingId: string): Promise<IParsing> => {
  const parsing = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/parsing/${parsingId}`
  );
  return parsing.data;
};

export const parsePdf = async (formData: FormData): Promise<any> => {
  const contentType = "multipart/form-data";
  const parsing = await AxiosPromise(
    "POST",
    `${url}/api/v1/admin/parsing/parse`,
    formData,
    {},
    contentType
  );
  return parsing.data;
};

export const deleteParsing = async (
  parsingId: string
): Promise<{
  ok?: number;
  n?: number;
}> => {
  const parsing = await AxiosPromise(
    "delete",
    `${url}/api/v1/admin/parsing/${parsingId}`
  );
  return parsing.data;
};

export const deleteParsingBatch = async (
  batchName: string
): Promise<{
  ok?: number;
  n?: number;
}> => {
  const parsing = await AxiosPromise(
    "delete",
    `${url}/api/v1/admin/parsing/batch/${batchName}`
  );
  return parsing.data;
};

export const downloadParsingPdf = async (doi: string): Promise<string> => {
  const pdf = await AxiosPromise(
    "get",
    `${parsingAPI}/api/v1/download_pdf?doi=${doi}`
  );
  return pdf.data;
};

// Organisations
export const listOrganisations = async (
  query?: IQuery
): Promise<{
  docs: IOrganisation[];
  meta: {
    total: number;
    offset: number;
    limit: number;
  };
}> => {
  const organisationList = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/organisation/`,
    {},
    {
      offset: 0,
      limit: 100,
      ...query,
    }
  );
  return organisationList.data;
};

export const getOrganisation = async (
  organisationId: string
): Promise<IOrganisation> => {
  const company = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/organisation/${organisationId}`
  );
  return company.data;
};

export const createOrganisation = async (
  data: ICreateOrganisationDto
): Promise<IOrganisation> => {
  const organisation = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/organisation`,
    data
  );
  return organisation.data;
};

export const updateOrganisation = async (
  orgnanisationId: string,
  data: IUpdateOrganisationDto
): Promise<IOrganisation> => {
  const organisation = await AxiosPromise(
    "put",
    `${url}/api/v1/admin/organisation/${orgnanisationId}`,
    data
  );
  return organisation.data;
};

export const deleteOrganisation = async (
  orgnanisationId: string
): Promise<{
  ok?: number;
  n?: number;
}> => {
  const organisation = await AxiosPromise(
    "delete",
    `${url}/api/v1/admin/organisation/${orgnanisationId}`
  );
  return organisation.data;
};

export const uploadOrganisationImage = async (
  organisationId: string,
  file: File
): Promise<IOrganisation> => {
  const contentType = "multipart/form-data";
  const formData = new FormData();
  formData.append("image", file);
  const organisation = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/organisation/logo/${organisationId}`,
    formData,
    {},
    contentType
  );
  return organisation.data;
};

export const listInvites = async (organisation: string): Promise<IInvite[]> => {
  const invite = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/organisation/invite-link?organisation=${organisation}`
  );
  return invite.data;
};

export const createInvite = async (room?: string): Promise<IInvite> => {
  const invite = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/organisation/invite-link`,
    {
      room,
    }
  );
  return invite.data;
};

export const deleteInvite = async (inviteCode: string): Promise<IInvite> => {
  const invite = await AxiosPromise(
    "delete",
    `${url}/api/v1/admin/organisation/invite-link/${inviteCode}`
  );
  return invite.data;
};

// Congress

export const listCongress = async (query?: {
  [key: string]: any;
}): Promise<{
  docs: ICongress[];
  meta: {
    limit: number;
    offset: number;
    total: number;
  };
}> => {
  const congressList = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/congress?${query ? queryString.stringify(query) : ""}`
  );
  return congressList.data;
};

export const getCongress = async (congressId: string): Promise<ICongress> => {
  const congress = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/congress/${congressId}`
  );
  return congress.data;
};

export const createCongress = async (
  data: ICreateCongressDto
): Promise<ICongress> => {
  const congress = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/congress`,
    data
  );
  return congress.data;
};

export const updateCongress = async (
  congressId: string,
  data: IUpdateCongressDto
): Promise<ICongress> => {
  const congress = await AxiosPromise(
    "put",
    `${url}/api/v1/admin/congress/${congressId}`,
    data
  );
  return congress.data;
};

export const deleteCongress = async (
  congressId: string
): Promise<{
  ok?: number;
  n?: number;
}> => {
  const congress = await AxiosPromise(
    "delete",
    `${url}/api/v1/admin/congress/${congressId}`
  );
  return congress.data;
};

export const uploadCongressImage = async (
  congressId: string,
  file: File
): Promise<ICongress> => {
  const contentType = "multipart/form-data";
  const formData = new FormData();
  formData.append("image", file);
  const congress = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/congress/${congressId}/image`,
    formData,
    {},
    contentType
  );
  return congress.data;
};

// Professions

export const getProfessionList = async (query?: {
  limit?: number;
  offset?: number;
  sortOrder?: "asc" | "desc";
}): Promise<{
  docs: IProfession[];
  meta: {
    total: number;
    limit: number;
    offset: number;
  };
}> => {
  // const professionList = await AxiosPromise("get", `${url}/api/v1/profession/`);
  const professionList = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/profession/`,
    undefined,
    query
  );

  return professionList.data;
};

export const getProfessionById = async (
  professionId: string
): Promise<IProfession> => {
  const profession = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/profession/${professionId}`
  );
  return profession.data;
};

export const updateProfession = async (
  professionId: string,
  data: IUpdateProfessionDto
): Promise<IProfession> => {
  const profession = await AxiosPromise(
    "put",
    `${url}/api/v1/admin/profession/${professionId}`,
    data
  );
  return profession.data;
};

export const createProfession = async (
  data: ICreateProfessionDto
): Promise<IProfession> => {
  const profession = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/profession/`,
    data
  );
  return profession.data;
};

export const deleteProfession = async (professionId: string) => {
  const profession = await AxiosPromise(
    "delete",
    `${url}/api/v1/admin/profession/${professionId}`
  );
  return profession.data;
};

// Tags
export const listTags = async (): Promise<ITag[]> => {
  const tagList = await AxiosPromise("get", `${url}/api/v1/admin/tag/`);
  return tagList.data;
};

export const listParentTags = async (): Promise<ITag[]> => {
  const tagList = await AxiosPromise("get", `${url}/api/v1/admin/tag/parents`);
  return tagList.data;
};

export const listTagsFromParent = async (parent: string): Promise<ITag[]> => {
  const tagList = await AxiosPromise(
    "get",
    `${url}/api/v1/tag/parent/${parent}`
  );
  return tagList.data;
};

export const getTag = async (uid: string): Promise<ITag> => {
  const tag = await AxiosPromise("get", `${url}/api/v1/tag/${uid}`);
  return tag.data;
};

export const createTag = async (data: ICreateTagDto): Promise<ITag> => {
  const tag = await AxiosPromise("post", `${url}/api/v1/tag`, data);
  return tag.data;
};

export const updateTag = async (
  uid: string,
  data: IUpdateTagDto
): Promise<ITag> => {
  const tag = await AxiosPromise("patch", `${url}/api/v1/tag/${uid}`, data);
  return tag.data;
};

export const deleteTag = async (
  uid: string
): Promise<{
  ok?: number;
  n?: number;
}> => {
  const tag = await AxiosPromise("delete", `${url}/api/v1/tag/${uid}`);
  return tag.data;
};

// Crawling

export const createCrawling = async (
  body: ICreateCrawlingDto
): Promise<ICrawling> => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/crawling/`,
    body
  );
  return data;
};

export const listCrawlings = async ({
  limit,
  offset,
  sortOrder,
  sortBy,
}: {
  limit: number;
  offset: number;
  sortOrder?: "asc" | "desc";
  sortBy?: "publicationDate" | "creationDate";
}): Promise<IPaginedResult<ICrawling>> => {
  const crawling = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/crawling`,
    undefined,
    {
      limit: limit || 10,
      offset: offset || 0,
      sortOrder,
      sortBy,
    }
  );
  return crawling.data;
};

export const getCrawling = async (crawlingId: string): Promise<ICrawling> => {
  const crawling = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/crawling/${crawlingId}`
  );
  return crawling.data;
};

export const updateCrawling = async (
  crawlingId: string,
  data: IUpdateCrawlingDto
): Promise<ICrawling> => {
  const crawling = await AxiosPromise(
    "patch",
    `${url}/api/v1/crawling/${crawlingId}`,
    data
  );
  return crawling.data;
};

export const uploadCrawlingItemPdf = async (
  formData: FormData
): Promise<ICrawling[]> => {
  const crawling = await AxiosPromise(
    "post",
    `${crawlingAPI}/api/v1/upload_pdf`,
    formData
  );
  return crawling.data;
};

export const downloadCrawlingItemPdf = async (doi: string): Promise<string> => {
  const pdf = await AxiosPromise(
    "get",
    `${crawlingAPI}/api/v1/download_pdf?doi=${doi}`
  );
  return pdf.data;
};

export const parseCrawlingItems = async (
  crawlingId: string,
  items: string[]
): Promise<IParsing[]> => {
  const crawling = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/crawling/${crawlingId}/parse`,
    { items }
  );
  return crawling.data;
};

export const deleteCrawlingItems = async (
  crawlingId: string,
  items: string[]
): Promise<ICrawling> => {
  const crawling = await AxiosPromise(
    "delete",
    `${url}/api/v1/crawling/${crawlingId}/items`,
    { items }
  );
  return crawling.data;
};

export const deleteCrawling = async (
  crawlingId: string
): Promise<{
  ok?: number;
  n?: number;
}> => {
  const crawling = await AxiosPromise(
    "delete",
    `${url}/api/v1/crawling/${crawlingId}`
  );
  return crawling.data;
};

// Translate

export const translateArticles = async (
  language: SupportedLanguage
): Promise<any> => {
  const crawling = await AxiosPromise(
    "post",
    `${parsingAPI}/api/v1/translate_all_article_task`,
    {
      language,
      content: {},
    }
  );
  return crawling.data;
};

export const translateArticleBySlug = async (
  language: SupportedLanguage | "PT-PT",
  slug: string
): Promise<IArticle | TranslateArticleErrorType> => {
  const crawling = await AxiosPromise(
    "post",
    `${parsingAPI}/api/v1/translate_article`,
    {
      language,
      slug,
    }
  );
  return crawling.data;
};

// Notification

export const sendNotification = async (
  payload: ICreateNotificationDto
): Promise<any> => {
  if (payload.organisation) {
    const organisationId = payload.organisation;
    delete payload.organisation;
    const notification = await AxiosPromise(
      "post",
      `${url}/api/v1/admin/notification/organisations/${organisationId}`,
      payload
    );
    return notification.data;
  } else {
    const notification = await AxiosPromise(
      "post",
      `${url}/api/v1/admin/notification`,
      payload
    );
    return notification.data;
  }
};

// Nectar
export const getListNectar = async (
  params: IQuery
): Promise<{
  docs: INectar[];
  meta: { total: number; limit: number; offset: number };
}> => {
  const { data } = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/nectar`,
    undefined,
    {
      ...params,
    }
  );
  return data;
};

export const searchNectar = async (
  searchValue: string,
  query?: IQuery
): Promise<{
  docs: INectar[];
  meta: { total: number; limit: number; offset: number };
}> => {
  const { data } = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/content/search/nectar?term=${searchValue}`,
    undefined,
    {
      ...query,
    }
  );
  return data;
};

export const getNectarById = async (nectarId: string): Promise<INectar> => {
  const { data } = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/nectar/${nectarId}`
  );

  return data;
};

export const deleteNectar = async (nectarId: string): Promise<INectar> => {
  const { data } = await AxiosPromise(
    "delete",
    `${url}/api/v1/admin/content/${nectarId}`
  );

  return data;
};

export const createNectar = async (payload: any) => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/nectar`,
    payload
  );
  return data;
};

export const updateNectar = async (
  nectarId: string,
  payload: any
): Promise<INectar> => {
  const { data } = await AxiosPromise(
    "put",
    `${url}/api/v1/admin/nectar/${nectarId}`,
    payload
  );
  return data;
};

export const getNectarQuestionList = async (
  params: IQuery
): Promise<{
  docs: INectarQuestion[];
  meta: { total: number; limit: number; offset: number };
}> => {
  const { data } = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/nectar-question`,
    undefined,
    params
  );

  return data;
};

export const getNectarQuestionById = async (
  nectarId: string
): Promise<INectarQuestion> => {
  const { data } = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/nectar-question/${nectarId}`
  );

  return data;
};

export const createNectarQuestion = async (data: {
  question: string;
  owner: string;
  language: SupportedLanguage;
}) => {
  const { data: nectar } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/nectar-question`,
    data
  );
  return nectar;
};

export const deleteNectarQuestion = async (nectarId: string) => {
  const { data } = await AxiosPromise(
    "delete",
    `${url}/api/v1/admin/nectar-question/${nectarId}`
  );

  return data;
};

export const convertNectarQuestion = async (nectarId: string) => {
  try {
    const { data } = await AxiosPromise(
      "post",
      `${url}/api/v1/admin/nectar/nectar-question/${nectarId}`
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const publishNectar = async (nectarId: string) => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/content/${nectarId}/publish`
  );

  return data;
};

export const unpublishNectar = async (nectarId: string) => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/content/${nectarId}/unpublish`
  );

  return data;
};

// Get hightlighted nectars
export const getNectarHighlights = async (params?: {
  language?: SupportedLanguage;
}) => {
  const { data } = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/nectar/highlight`,
    undefined,
    params
  );

  return data;
};

// Set nectar as 'highlight'
export const addNectarHightlights = async (nectarId: string) => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/nectar/${nectarId}/highlight`
  );

  return data;
};

export const deleteNectarHightlights = async (nectarId: string) => {
  const { data } = await AxiosPromise(
    "delete",
    `${url}/api/v1/admin/nectar/${nectarId}/highlight`
  );

  return data;
};

export const updateHighlight = async (
  highlightId: string,
  highlight: Array<{ nectar: string; position: number }>
) => {
  const { data } = await AxiosPromise(
    "put",
    `${url}/api/v1/admin/nectar/highlight/${highlightId}`,
    {
      highlight,
    }
  );

  return data;
};

export const createAppAnnouncement = async (body: {
  contents: Array<{
    title: string;
    content: string;
    language: SupportedLanguage;
    cta: string;
  }>;
  organisation?: string;
}) => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/announcement`,
    body
  );

  return data;
};

export const getTranslatedArticleFromDoi = async (doi: string) => {
  const { data } = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/article/translated-slug-by-doi`,
    undefined,
    { doi }
  );

  return data;
};

export const getAdminsPermissionsList = async (params: {
  limit?: number;
  offset?: number;
  organisation?: string;
}): Promise<{
  docs: IUserAdmin[];
  meta: {
    total: number;
    limit: number;
    offset: number;
  };
}> => {
  const { data } = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/permission/admins`,
    undefined,
    params
  );

  return data;
};

export const updateAdminPermissions = async (body: {
  user: string; // User._id
  permissions: {
    label: PermissionLabel;
    permissions: PermissionEnum[];
  }[];
}) => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/permission`,
    body
  );

  return data;
};

export const enableUser = async (userId: string) => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/user/${userId}/enable`
  );

  return data;
};

export const disableUser = async (userId: string) => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/user/${userId}/disable`
  );

  return data;
};

export const addContentToContent = async (
  associatedContentId: string,
  contentId: string
): Promise<IContent> => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/content/${associatedContentId}/content`,
    { contentId }
  );

  return data;
};

export const removeContentFromContent = async (
  associatedContentId: string,
  contentId: string
) => {
  const { data } = await AxiosPromise(
    "delete",
    `${url}/api/v1/admin/content/${associatedContentId}/content`,
    { contentId }
  );

  return data;
};

export const addContentToPlaylist = async (params: {
  contentId: string;
  playlistId: string;
}) => {
  const { contentId, playlistId } = params;

  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/playlist/${playlistId}/content`,
    {
      contentId,
    }
  );

  return data;
};

export const deleteContentFromPlaylist = async (params: {
  contentId: string;
  playlistId: string;
}) => {
  const { contentId, playlistId } = params;
  const { data } = await AxiosPromise(
    "DELETE",
    `${url}/api/v1/admin/playlist/${playlistId}/content`,
    { contentId }
  );

  return data;
};

export const updateOrganisationFeature = async (
  organisationId: string,
  body: { feature: FeatureEnum; enabled: boolean; limit?: number }
) => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/organisation/${organisationId}/config-feature`,
    body
  );

  return data;
};

export const updateOrganisationLanguages = async (
  organisationId: string,
  body: { allowedLanguages: SupportedLanguage[] }
) => {
  const { data } = await AxiosPromise(
    "put",
    `${url}/api/v1/admin/organisation/settings/${organisationId}`,
    body
  );

  return data;
};

export const updateOrganisationSettings = async (
  organisationId: string,
  settings: {
    allowedLanguages?: SupportedLanguage[];
    backgroundColor?: string;
    medicalSpecialties?: string[];
    discoveryFilter?: boolean;
    professions?: string[];
    mainSpecialty?: string;
    primaryColor?: string;
    secondaryColor?: string;
    subtitle?: string;
  }
): Promise<IOrganisation> => {
  const { data } = await AxiosPromise(
    "put",
    `${url}/api/v1/admin/organisation/settings/${organisationId}`,
    settings
  );

  return data;
};

export const postRetryParsing = async (taskId: string) => {
  const { data } = await AxiosPromise(
    "post",
    `${parsingAPI}/api/v1/task/${taskId}/retry`
  );

  return data;
};

export const postRetryAllParsing = async () => {
  const { data } = await AxiosPromise(
    "post",
    `${parsingAPI}/api/v1/task/retry_all_failed`
  );

  return data;
};

export const postArticleSynthetize = async (articleId: string) => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/article/${articleId}/synthesize`
  );

  return data;
};

export const downloadNectarCsv = async (nectarIds: string[]) => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/nectar/download`,
    { nectarIds }
  );

  return data;
};

export const postAssociateVideoToArticle = async (
  articleId: string,
  video: string
) => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/article/${articleId}/associate-video`,
    { video }
  );

  return data;
};

export const postDissociateVideoToArticle = async (articleId: string) => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/article/${articleId}/dissociate-video`
  );

  return data;
};

/**
 * Rooms Endpoints
 */

export const createRoom = async (room: ICreateRoomDto): Promise<IRoom> => {
  const { data } = await AxiosPromise("post", `${url}/api/v1/admin/room`, room);

  return data;
};

export const postConfigureRoom = async (
  roomId: string,
  config: IConfigureRoomDto
): Promise<IRoom> => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/room/${roomId}/configure`,
    config
  );

  return data;
};

export const getRoomsList = async (
  organisation?: string
): Promise<Array<IRoom>> => {
  const { data } = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/room`,
    undefined,
    { organisation }
  );

  return data;
};

export const getRoomById = async (roomId: string): Promise<IRoom> => {
  const { data } = await AxiosPromise(
    "get",
    `${url}/api/v1/admin/room/${roomId}`
  );

  return data;
};

export const postArticleToRoom = async (roomId: string, article: string) => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/room/${roomId}/article`,
    { article }
  );

  return data;
};

export const deleteArticleFromRoom = async (
  roomId: string,
  article: string
) => {
  const { data } = await AxiosPromise(
    "delete",
    `${url}/api/v1/admin/room/${roomId}/article`,
    { article }
  );

  return data;
};

export const postContentToRoom = async (roomId: string, content: string) => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/room/${roomId}/content`,
    { content }
  );

  return data;
};

export const deleteContentFromRoom = async (
  roomId: string,
  content: string
) => {
  const { data } = await AxiosPromise(
    "delete",
    `${url}/api/v1/admin/room/${roomId}/content`,
    { content }
  );

  return data;
};

export const postPlaylistToRoom = async (roomId: string, playlist: string) => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/room/${roomId}/playlist`,
    { playlist }
  );

  return data;
};

export const deletePlaylistFromRoom = async (
  roomId: string,
  playlist: string
) => {
  const { data } = await AxiosPromise(
    "delete",
    `${url}/api/v1/admin/room/${roomId}/playlist`,
    { playlist }
  );

  return data;
};

export const postPinnedContentToRoom = async (body: {
  roomId: string;
  contentId: string;
  language: SupportedLanguage;
}) => {
  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/room/${body.roomId}/pinned-content`,
    { contentId: body.contentId, language: body.language }
  );

  return data;
};

export const postRoomLogo = async (roomId: string, image: File) => {
  const formData = new FormData();
  formData.append("image", image);

  const { data } = await AxiosPromise(
    "post",
    `${url}/api/v1/admin/room/${roomId}/logo`,
    formData,
    {},
    "multipart/form-data"
  );

  return data;
};

import slugify from "slugify";
import {
  IOrganisation,
  IUserAdmin,
  IUserRole,
  OrganisationUid,
  PermissionEnum,
  PermissionLabel,
  SupportedLanguage,
} from "../model";
import { DefaultOptionType } from "antd/lib/select";

export const languageLabel = {
  [SupportedLanguage.EN]: "English",
  [SupportedLanguage.FR]: "French",
  [SupportedLanguage.IT]: "Italian",
  [SupportedLanguage.ES]: "Spanish",
  [SupportedLanguage.DE]: "German",
  [SupportedLanguage.PT]: "Portugese",
  [SupportedLanguage.AR]: "Arabic",
};

export const languageOptions = [
  { value: SupportedLanguage.EN, label: "🇬🇧 English" },
  { value: SupportedLanguage.FR, label: "🇫🇷 French" },
  { value: SupportedLanguage.IT, label: "🇮🇹 Italian" },
  { value: SupportedLanguage.ES, label: "🇪🇸 Spanish" },
  { value: SupportedLanguage.DE, label: "🇩🇪 German" },
  { value: SupportedLanguage.PT, label: "🇵🇹 Portugese" },
  { value: SupportedLanguage.AR, label: "🇦🇪 Arabic" },
];

export function languageOptionsOrganisation(organisation?: IOrganisation) {
  const allowedLanguages = organisation?.organisationSettings.allowedLanguages;

  const languages = Object.keys(SupportedLanguage)
    .filter((lng) =>
      allowedLanguages?.includes(lng.toLowerCase() as SupportedLanguage)
    )
    .map((lng) => ({
      value: lng.toLowerCase(),
      label: languageLabel[lng.toLocaleLowerCase() as SupportedLanguage],
    }));

  return languages;
}

export const checkPermission = (
  user: IUserAdmin,
  permissionLabel: PermissionLabel,
  permission?: PermissionEnum
) => {
  if (!user) return false;
  if (user?.roles?.includes(IUserRole.SUPER_ADMIN)) return true;

  return user?.adminPermissions
    ?.find((permission) => permission.label === permissionLabel)
    ?.permissions.some(
      (subPermission) => subPermission === (permission || PermissionEnum.Read)
    );
};

export function searchFilterOptions(
  input?: string,
  option?: DefaultOptionType
) {
  if ((!option?.children && !option?.label) || !input) return false;

  let children;
  if (option.children)
    children = slugify(option.children as unknown as string).toLowerCase();
  else if (option.label)
    children = slugify(option.label as unknown as string).toLowerCase();

  if (!children) return false;

  return children.includes(slugify(input).toLowerCase());
}

export function getPermissionsOrganisation(organisation?: IOrganisation) {
  const organisationPermissions = [
    PermissionLabel.Content,
    PermissionLabel.SensitiveData,
  ];

  if (!!organisation?.uid && organisation.uid !== OrganisationUid.JUISCI)
    return organisationPermissions;

  return Object.values(PermissionLabel);
}

export function pathToSlug(path: string) {
  const newPath = path.replace(/\//g, "-").replace(/^-|-$/g, "");
  return slugify(newPath, { lower: true });
}

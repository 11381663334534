import {
  Button,
  Card,
  Col,
  Comment,
  Divider,
  Input,
  Layout,
  Row,
  Spin,
  Tag,
  Tooltip,
  Upload,
  message,
  notification,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  feedbackVideoRequest,
  getVideoRequest,
  postVideoRequest,
} from "../services";
import { useEffect, useState } from "react";
import { IVideoRequest, SupportedLanguage } from "../model";
import {
  ArrowLeftOutlined,
  DownloadOutlined,
  SaveOutlined,
  SendOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import ApiVideoPlayer from "@api.video/react-player";
import moment from "moment";
import styled from "styled-components";

const VideoRequestReview = () => {
  const { id: requestId, language: videoLanguage } = useParams();
  const navigate = useNavigate();
  const [videoRequest, setVideoRequest] = useState<IVideoRequest | null>(null);
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [currentComment, setCurrentComment] = useState<string>("");
  const [videoTimer, setVideoTimer] = useState<number>(0);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    fetchRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId]);

  const video = videoRequest?.videos.find(
    (video) => video.language === videoLanguage
  );

  const fetchRequest = async () => {
    if (!requestId) return;
    const req = await getVideoRequest(requestId);

    setVideoRequest(req);
  };

  const handleSaveReview = async () => {
    if (!requestId || !videoRequest || !video) {
      navigate(-1);
      return console.error("Missing data");
    }

    setIsSaving(true);
    if (videoFile)
      await postVideoRequest(requestId, videoFile, {
        title: videoFile.name || video.title || "",
        language: video.language || "",
      });

    message.success("Video uploaded successfully, review saved!");
    setIsSaving(false);
  };

  const secondsToMMSS = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const handleSendFeedback = async () => {
    const feedback = {
      message: currentComment || "",
      timer: secondsToMMSS(videoTimer),
      language: videoLanguage as SupportedLanguage,
      requestId: requestId || "",
    };

    const newRequest = await feedbackVideoRequest(feedback);
    notification.success({
      message: "Feedback sent",
    });

    setVideoRequest(newRequest);
    setCurrentComment("");
  };

  if (!videoRequest || !video) return <Spin />;

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Layout.Content
        style={{
          padding: "20px 50px 200px",
        }}
      >
        <Row
          justify='space-between'
          align='middle'
          style={{ margin: "20px 0" }}
        >
          <Col>
            <Button
              type='link'
              style={{ padding: 0, marginBottom: 16 }}
              icon={<ArrowLeftOutlined />}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <h1
              style={{ fontWeight: 800, fontSize: "30px" }}
              onClick={() => console.log("videoRequest:", videoRequest)}
            >
              {videoRequest.name}
            </h1>
          </Col>
          <Col>
            <Button
              style={{ marginRight: 10 }}
              onClick={handleSaveReview}
              type={"primary"}
              icon={<SaveOutlined />}
              loading={isSaving}
            >
              Save changes
            </Button>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={14}>
            <ApiVideoPlayer
              video={{
                id:
                  videoRequest?.videos.find(
                    (video) => video.language === videoLanguage
                  )?.videoId || "",
              }}
              style={{
                width: "100%",
                height: 500,
              }}
              onTimeUpdate={(timer) => setVideoTimer(timer)}
            />
            <Divider />

            <StyledUpload
              accept='.mp4'
              customRequest={(payload) => {
                if (payload.onSuccess) payload.onSuccess("ok");
              }}
              onChange={({ file }) => {
                if (file.status === "done") {
                  setVideoFile(file.originFileObj as File);
                } else if (file.status === "error") {
                  message.error(`${file.name} file upload failed.`);
                }
              }}
            >
              <Button
                style={{ width: "100%" }}
                htmlType='button'
                icon={<UploadOutlined />}
              >
                Upload Video
              </Button>
            </StyledUpload>
            <div style={{ display: "none" }}>
              <Divider />
              <Input.TextArea
                placeholder='Add a comment'
                onChange={(e) => setCurrentComment(e.target.value)}
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
              <Button
                icon={<SendOutlined />}
                type='primary'
                style={{ marginTop: 10, float: "right" }}
                onClick={handleSendFeedback}
              >
                Send comment
              </Button>
            </div>
          </Col>
          <Col span={10}>
            <Card title={video.title}>
              <p>
                <b>Label:</b> {videoRequest.name}
              </p>
              <Button
                icon={<DownloadOutlined />}
                type='link'
                style={{ padding: 0 }}
                href={videoRequest.pdf.url}
                target='_blank'
              >
                {"Download PDF"}
              </Button>
            </Card>
            <Divider />
            <Card title={"Comments"}>
              {[...video.feedbacks]?.reverse().map((feedback, index) => (
                <div
                  key={feedback._id}
                  style={{
                    opacity: feedback.status === "Done" ? 0.5 : 1,
                  }}
                >
                  <Comment
                    author={
                      <div>
                        <Tag color='volcano'>{feedback.timer || "00:00"}</Tag>
                        <Tooltip
                          title={moment(feedback.createdAt).format(
                            "YYYY/MM/DD [at] HH:mm"
                          )}
                        >
                          <span>{moment(feedback.createdAt).fromNow()}</span>
                        </Tooltip>
                      </div>
                    }
                    content={
                      <div>
                        <p style={{ margin: "8px 0" }}>{feedback.message}</p>
                        <small>
                          {feedback.status === "Done"
                            ? "✅ Done"
                            : "⏳ In progress"}
                        </small>
                      </div>
                    }
                  />
                  {index !== video.feedbacks.length - 1 && <Divider />}
                </div>
              ))}
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default VideoRequestReview;

const StyledUpload = styled(Upload)`
  div.ant-upload {
    width: 100%;
  }
`;

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  getNectarHighlights,
  getRoomsList,
  listCompanies,
  listJournals,
  listOrganisations,
  listTags,
} from "../services";
import {
  FETCH_DATA_SUCCESS,
  FETCH_DATA_ERROR,
  FETCH_JOURNALS,
  FETCH_TAGS,
  FETCH_ORGANISATIONS,
  FETCH_COMPANIES,
  FETCH_HIGHLIGHT_NECTARS,
  FETCH_ROOMS,
} from "./types";

export const fetchDataSuccess = (
  key: string,
  data: any
): FetchDataSuccessAction => {
  return {
    type: FETCH_DATA_SUCCESS,
    key,
    data,
  };
};

export const fetchDataError = (error: any): FetchDataErrorAction => {
  return {
    type: FETCH_DATA_ERROR,
    error,
  };
};

export const fetchData = (type: string): any => {
  switch (type) {
    case FETCH_COMPANIES:
      return async (dispatch: any) => {
        const companyList = await listCompanies();
        return dispatch(fetchDataSuccess("companyList", companyList));
      };
    case FETCH_JOURNALS:
      return async (dispatch: any) => {
        const journalList = await listJournals();
        return dispatch(fetchDataSuccess("journalList", journalList));
      };
    case FETCH_TAGS:
      return async (dispatch: any) => {
        const tagList = await listTags();
        return dispatch(fetchDataSuccess("tagList", tagList));
      };
    case FETCH_ORGANISATIONS:
      return async (dispatch: any) => {
        const { docs: organisationList } = await listOrganisations();
        return dispatch(fetchDataSuccess("organisationList", organisationList));
      };

    case FETCH_HIGHLIGHT_NECTARS:
      return async (dispatch: any) => {
        const highlights = await getNectarHighlights();

        return dispatch(fetchDataSuccess("highlightNectars", highlights));
      };

    case FETCH_ROOMS:
      return async (dispatch: any) => {
        const rooms = await getRoomsList();
        return dispatch(fetchDataSuccess("roomList", rooms));
      };
    default:
      return;
  }
};

interface FetchDataSuccessAction {
  type: typeof FETCH_DATA_SUCCESS;
  key: string;
  data: any;
}

interface FetchDataErrorAction {
  type: typeof FETCH_DATA_ERROR;
  error: any;
}

export type ActionTypes = FetchDataSuccessAction | FetchDataErrorAction;
